const defaultState = { light: false };

export default (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case "headerColor/toggle":
            return { ...state, light: !state.light };
        case "headerColor/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};
