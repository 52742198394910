import React, { useEffect } from "react";
import { usePath, useRoutes } from "hookrouter";
import { dispatch } from "./redux";
import { currentLang } from "helpers";

import Home from "views/Home";
import Page from "views/Page";
import NewsArticlePage from "views/NewsArticlePage";
import NewsFilterPage from "views/NewsFilterPage/NewsFilterPage";
import ProgramPage from "views/ProgramPage/ProgramPage";
import SingleEventPage from "views/SingleEventPage";
import SingleFestivalPage from "views/SingleFestivalPage";
import ChaptersPage from "views/ChaptersPage";
import SingleChapterPage from "views/SingleChapterPage";
import ErrorPage from "views/ErrorPage";

const routes = {
  "/en": () => <Home />,
  "/en/home-2": () => <Home />,
  "/en/news": (params) => <NewsFilterPage {...params} />,
  "/en/news/:slug": ({ slug }) => <NewsArticlePage slug={slug} />,
  "/en/events/:slug": ({ slug }) => <SingleEventPage slug={slug} />,
  "/en/program-en": (params) => <ProgramPage {...params} />,
  "/en/festivals/:slug": ({ slug }) => <SingleFestivalPage slug={slug} />,
  "/en/chapters": (params) => <ChaptersPage {...params} />,
  "/en/chapters/:slug": ({ slug }) => <SingleChapterPage slug={slug} />,
  "/en/:slug": ({ slug }) => <Page slug={slug} />,
  "/en/:parent/:slug": (params) => <Page {...params} />,
  "/en/:grandparent/:parent/:slug": (params) => <Page {...params} />,

  "/": () => <Home />,
  "/home": () => <Home />,
  "/nyheter": (params) => <NewsFilterPage {...params} />,
  "/nyheter/:slug": ({ slug }) => <NewsArticlePage slug={slug} />,
  "/arrangementer/:slug": ({ slug }) => <SingleEventPage slug={slug} />,
  "/program": (params) => <ProgramPage {...params} />,
  "/festivaler/:slug": ({ slug }) => <SingleFestivalPage slug={slug} />,
  "/avdelinger": (params) => <ChaptersPage {...params} />,
  "/avdelinger/:slug": ({ slug }) => <SingleChapterPage slug={slug} />,
  "/:slug": ({ slug }) => <Page slug={slug} />,
  "/:parent/:slug": (params) => <Page {...params} />,
  "/:grandparent/:parent/:slug": (params) => <Page {...params} />,
};

const Routes = () => {
  const hookedRoutes = useRoutes(routes);
  const path = usePath();

  useEffect(() => {
    window.scrollTo(0, 0);
    // Set site language
    if (currentLang() === "en") {
      dispatch("lang/en");
    } else {
      dispatch("lang/no");
    }
  }, [path]);
  // Handle trailing slash path
  if (path && path !== "/" && path.slice(-1) === "/") {
    window.location = path.slice(0, -1);
  }
  if (path && path.indexOf(".") !== -1) {
    return <ErrorPage />;
  }

  return hookedRoutes || <ErrorPage />;
};

export default Routes;
