const defaultState = 'no';

export default (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case "lang/no":
            return 'no';
        case "lang/en":
            return 'en';
        case "lang/clear":
        case "REDUX/CLEAR":
            return defaultState;
        default:
            return state;
    }
};
