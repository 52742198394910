import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Media from '../css/Media';


/* #################################### Instructions for use ####################################
    
    Require prop-types, styled-components and styled-media-query.

    * Before use, change entries in the 'breakpoints' array to fit project media queries.
    * If you want max-width on breakpoints, change all greaterThan() functions to lessThan().
    * If omitted, grid-column starts on 1 and ends on 2
    * If omitted, grid-row starts on 1. Grid container must have specified grid-template-rows for grid-row end to work.
    * To use media queries, pass an object to the prop instead of its designated prop type. This object 
    must consist of a 'default' key, followed by the relevant breakpoint rules.
    * Since IE does not allow negative integers, please do not use this unless you have 
    a secret kink for inconsistant browser behavior.

    Example:
        <div style={{display: "grid"; grid-template-columns: "repeat(12, 1fr)"; grid-template-rows: "repeat(12, 1fr)"}}>
            <GridElement
                justify="center"
                align={{default: "center", s: "start"}}
                colStart={1}
                colEnd={12}
                colSpan
                rowStart={{default: 1, s: 3, l: 4}}
                rowEnd={{default: 12, s: 9, l: 8}}
            >
                <code>Look at all this content</code>
            </GridElement>
        </div>

    Still to be done: 
        - Media query support for colSpan/rowSpan
*/

// Define breakpoints to fit project media queries
const breakpoints = ['s', 'm', 'l'];

class GridElement extends Component {
    constructor(props) {
        super(props);

        // Setting element default values based on passed props
        this.state = {
            justify: typeof props.justify === 'string' ? { default: props.justify } : props.justify,
            align: typeof props.align === 'string' ? { default: props.align } : props.align,
            place: Array.isArray(props.place) ? { default: [props.place[0], props.place[1]] } : props.place,
            area: typeof props.area === 'string' ? { default: props.area } : props.area,
            colStart: !props.colStart ? { default: 1 } : isNaN(props.colStart) ? props.colStart : { default: props.colStart },
            colEnd: !props.colEnd ? (props.colStart ? { default: props.colStart + 1} : { default: 2 }) : isNaN(props.colEnd) ? props.colEnd : { default: props.colEnd },
            colSpan: props.colSpan ? 1 : 0,
            rowStart: !props.rowStart ? { default: 1 } : isNaN(props.rowStart) ? props.rowStart : { default: props.rowStart },
            rowEnd: isNaN(props.rowEnd) ? props.rowEnd : { default: props.rowEnd },
            rowSpan: props.rowSpan ? 1 : 0,
            calcSpanCol: false,
            calcSpanRow: false,
        }
    }

    componentDidMount() {
        this.setState({
            calcSpanCol: this.props.colStart && this.props.colEnd ? this.convertToSpan(this.props.colStart, this.props.colEnd) : {default: 1},
            calcSpanRow: this.props.rowStart && this.props.rowEnd ? this.convertToSpan(this.props.rowStart, this.props.rowEnd) : {default: 1},
        })
    }

    convertToSpan = (start, end) => {
        // span must be > 0
        let span = {default: (start.default === end.default) ? 1 : end.default - start.default};

        if(isNaN(start && end)) {
            breakpoints.map(bp => {
                if(start[bp] && end[bp]) {
                    span[bp] = (start[bp] === end[bp]) ? 1 : end[bp] - start[bp];
                }
                return null;
            })
            return span;
        } else {
            span.default = (start === end) ? 1 : end - start;
            return span;
        }
    }

    render() {
        const {
            justify,
            align,
            place,
            area,
            colStart,
            colEnd,
            colSpan,
            rowStart,
            rowEnd,
            rowSpan,
            calcSpanCol,
            calcSpanRow } = this.state;

        return (
            <Wrapper
                justify={justify}
                align={align}
                place={place}
                area={area}
                colStart={colStart}
                colEnd={colEnd}
                colSpan={colSpan}
                rowStart={rowStart}
                rowEnd={rowEnd}
                rowSpan={rowSpan}
                calcSpanCol={calcSpanCol}
                calcSpanRow={calcSpanRow}
                className={this.props.className}
                onClick={this.props.onClick}
                id={this.props.id}
                fixed={this.props.fixed}
            >
                {this.props.children}
            </Wrapper>
        )
    }
}

GridElement.propTypes = {
    justify: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    align: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    place: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    area: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    colStart: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    colEnd: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    colSpan: PropTypes.bool,
    rowStart: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    rowEnd: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    rowSpan: PropTypes.bool,
}

export default GridElement;


/* Get media queries for component attributes */

const getBPJustify = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-column-align: ${prop[bp]};
                justify-self: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPAlign = (bp, prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-row-align: ${prop[bp]};
                align-self: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPArea = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-area: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPPlace = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-row-align: ${prop[bp][0]};
                -ms-grid-column-align: ${prop[bp][1]};
                place-self: ${prop[bp][0]} ${prop[bp][1]};
            `.join('')
        )
    } else return null;
}

const getBPColStartIE = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-column: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPColSpanIE = (bp, prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-column-span: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPColTrack = (bp,prop_1, prop_2) => {
    if(prop_1[bp] && prop_2[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-column: ${prop_1[bp]} / ${prop_2[bp]};
            `.join('')
        )
    } else return null;
}

const getBPColStart = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-column-start: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPColSpan = (bp, prop_1, prop_2) => {
    if(prop_1[bp] && prop_2[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-column: ${prop_1[bp]} / span ${prop_2[bp]};
            `.join('')
        )
    } else return null;
}

const getBPRowStartIE = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-row: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPRowSpanIE = (bp,prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                -ms-grid-row-span: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPRowTrack = (bp, prop_1, prop_2) => {
    if(prop_1[bp] && prop_2[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-row: ${prop_1[bp]} / ${prop_2[bp]};
            `.join('')
        )
    } else return null;
}

const getBPRowStart = (bp, prop) => {
    if(prop[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-row-start: ${prop[bp]};
            `.join('')
        )
    } else return null;
}

const getBPRowSpan = (bp, prop_1, prop_2) => {
    if(prop_1[bp] && prop_2[bp]) {
        return (
            Media.greaterThan(bp)`
                grid-row: ${prop_1[bp]} / span ${prop_2[bp]};
            `.join('')
        )
    } else return null;
}


const Wrapper = styled.div`

    ${props => props.justify && css`
        -ms-grid-column-align: ${props.justify.default};
        justify-self: ${props.justify.default};

        ${breakpoints.map(bp => getBPJustify(bp, props.justify))}
    `}

    ${props => props.align && css`
        -ms-grid-row-align: ${props.align.default};
        align-self: ${props.align.default};

        ${breakpoints.map(bp => getBPAlign(bp, props.align))}
    `}

    ${props => props.area && css`
        grid-area: ${props.area.default};

        ${breakpoints.map(bp => getBPArea(bp, props.area))}
    `}

    ${props => props.place && css`
        -ms-grid-row-align: ${props.place.default[0]};
        -ms-grid-column-align: ${props.place.default[1]};
        place-self: ${props.place.default[0]} ${props.place.default[1]};

        ${breakpoints.map(bp => getBPPlace(bp, props.place))}
    `}

    /* define column start IE fix */
    -ms-grid-column: ${props => props.colStart.default};

    ${breakpoints.map(bp => getBPColStartIE(bp, props => props.colStart))}

    /* if there is a defined endpoint IE fix */
    ${props => props.calcSpanCol !== false && css`
        -ms-grid-column-span: ${props.calcSpanCol.default};
        
        ${breakpoints.map(bp => getBPColSpanIE(bp, props.calcSpanCol))}
    `}

    /* if there is a defined end point */
    ${props => !props.colSpan && props.colEnd && css`
        grid-column: ${props.colStart.default} / ${props.colEnd.default};

        ${breakpoints.map(bp => getBPColTrack(bp, props.colStart, props.colEnd))}
    `}

    /* if there is no defined end point */
    ${props => (!props.colSpan && !props.colEnd) && css`
        grid-column-start: ${props.colStart.default};

        ${breakpoints.map(bp => getBPColStart(bp, props.colStart))}
    `}

    /* if span is true */
    ${props => props.colSpan === 1 && css`
        grid-column: ${props.colStart.default} / span ${props.calcSpanCol.default};

        ${breakpoints.map(bp => getBPColSpan(bp, props.colStart, props.calcSpanCol))}
    `}

    /* define row start IE fix */
    -ms-grid-row: ${props => props.rowStart.default};

    ${breakpoints.map(bp => getBPRowStartIE(bp, props => props.rowStart))}

    /* if there is a defined endpoint IE fix */
    ${props => props.calcSpanRow && css`
        -ms-grid-row-span: ${props.calcSpanRow.default};

        ${breakpoints.map(bp => getBPRowSpanIE(bp, props.calcSpanRow))}
    `}

    /* if there is a defined end point */
    ${props => (!props.rowSpan && props.rowEnd) && css`
        grid-row: ${props.rowStart.default} / ${props.rowEnd.default};

        ${breakpoints.map(bp => getBPRowTrack(bp, props.rowStart, props.rowEnd))}
    `}

    /* if there is no defined end point */
    ${props => (!props.rowSpan && !props.rowEnd) && css`
        grid-row-start: ${props.rowStart.default};

        ${breakpoints.map(bp => getBPRowStart(bp, props.rowStart))}
    `}

    /* if span is true */
    ${props => props.rowSpan === 1 && css`
        grid-row: ${props.rowStart.default} / span ${props.calcSpanRow.default};

        ${breakpoints.map(bp => getBPRowSpan(bp, props.rowStart, props.calcSpanRow))}
    `}
`