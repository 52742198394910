import React from 'react';

export default props => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="17.667" height="17.667" viewBox="0 0 17.667 17.667" className={props.className} onClick={props.onClick} open={props.open}>
            <g id="Group_9809" data-name="Group 9809" transform="translate(-1310.666 -45.666)">
                <line id="Line_2408" data-name="Line 2408" x1="14.839" y1="14.839" transform="translate(1312.081 47.081)" fill="none" stroke="#120f0b" strokeLinecap="round" strokeWidth="2"/>
                <line id="Line_2409" data-name="Line 2409" x1="14.839" y1="14.839" transform="translate(1326.919 47.081) rotate(90)" fill="none" stroke="#120f0b" strokeLinecap="round" strokeWidth="2"/>
            </g>
        </svg>
    );
}