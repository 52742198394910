import React from 'react';
import styled from 'styled-components';

export default props => {
    return (
        <FilterBar className={props.className}>{props.children}</FilterBar>
    )
}

const FilterBar = styled.div`
    display: flex;
    border-bottom: 1px solid ${props => props.theme.colors.dark};
    padding-bottom: 16px;
`