import styled from "styled-components";
import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useState, useRef } from "react";

import Container from "components/Container";
import GridElement from "components/GridElement";
import PlusIcon from "icons/PlusIcon";
import MinusIcon from "icons/MinusIcon";

const SelectItem = (props) => {
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [bool, setBool] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  const handleClick = (val) => {
    let arr = list;

    if (list.includes(val)) {
      arr = arr.filter((item) => item !== val);
    } else {
      arr = [...arr, val];
    }
    setList(arr);
    setBool(!bool);
    props.handleChange(arr);
  };

  const handleOpen = () => {
    setOpen(true);
    //hacky way to make sure date input is closed, if that is where we clicked from
    window.setTimeout(() => props.setVisibility(true), 50);
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      props.setVisibility(false);
    } else return null;
  };

  return (
    <Wrapper ref={ref}>
      <Container>
        <TitleGridElement colStart={1} colEnd={14} rowStart={1}>
          <InputTitle
            onClick={open ? () => handleClose() : () => handleOpen()}
            isBlack={true}
            open={open}
          >
            {props.name}
          </InputTitle>
        </TitleGridElement>
        {open ? (
          <OptionGridElement colStart={5} colEnd={13} rowStart={1}>
            {props.arr.map((item, i) => {
              let hackToForceReRender = bool;
              return (
                <Options
                  onClick={() => handleClick(item)}
                  isSelected={list.includes(item)}
                  hasLength={list.length > 0}
                  key={i}
                >
                  {item}
                </Options>
              );
            })}
          </OptionGridElement>
        ) : null}
        {!open ? (
          <ButtonGridElement
            colStart={13}
            colEnd={14}
            rowStart={1}
            justify="end"
            align="center"
            onClick={() => handleOpen()}
          >
            <PlusIcon />
          </ButtonGridElement>
        ) : null}
        {open ? (
          <ButtonGridElement
            colStart={13}
            colEnd={14}
            rowStart={1}
            justify="end"
            align="start"
            minus
            onClick={() => handleClose()}
          >
            <MinusIcon />
          </ButtonGridElement>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default SelectItem;

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledP = styled.p`
  ${(props) => props.theme.fonts.h7}
  padding-bottom: 16px;
  width: 100%;
`;

const TitleGridElement = styled(GridElement)`
  display: flex;
`;

const InputTitle = styled(StyledP)`
  padding-bottom: ${(p) => (p.open ? 16 : 0)}px;
`;

const Options = styled(StyledP)`
  position: relative;
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.text
      : !props.hasLength
      ? props.theme.colors.text
      : props.theme.colors.alt};
`;

const OptionGridElement = styled(GridElement)``;

const ButtonGridElement = styled(GridElement)`
  svg {
    margin-bottom: ${(p) => (p.minus ? 6 : 0)}px;
  }
`;
