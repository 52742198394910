import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import useRequest from "kb-gql";

import { dispatch } from "../../../redux";

import MobileHeader from "./MobileHeader";
import LottieLogo from "./LottieLogo";
import LottieHoverLogo from "./LottieHoverLogo";
import Hamburger from "../Hamburger";
import Menu from "../Menu";
import Search from "../Search/Search";
import Container from "../../Container";
import GridElement from "../../GridElement";
import LanguageSwitcher from "../LanguageSwitcher";
import SearchButton from "../Search/SearchButton";
import A from "../../A";

import useIsMobile from "hooks/useIsMobile";

import Media from "css/Media";

const menuQuery = (lang) => `
    menus(where: {location: ${
      lang === "no" ? "MAIN_MENU" : "MAIN_MENU___EN"
    }}) {
        nodes {
            menuItems {
                nodes {
                    label
                    url
                }
            }
        }
    }
`;

function Header() {
  const isMobile = useIsMobile();
  const [scrollHeight, setScrollHeight] = useState(window.scrollY);
  const [hover, setHover] = useState(false);

  const menuIsOpen = useSelector((state) => state.menuState.open);
  const searchIsOpen = useSelector((state) => state.searchState.open);
  const chapter = useSelector((state) => state.chapter);
  const lang = useSelector((state) => state.lang);
  const isHome = useSelector((state) => state.currentView.view === "home");
  const storeSearch = useSelector((state) => state.searchInput);

  const data = useRequest(menuQuery(lang), "mainMenu", "nodes");

  useEffect(() => {
    const setScroll = () => setScrollHeight(window.scrollY);
    //if home:
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const toggleMenu = () => {
    dispatch("openMenu/toggle");
    dispatch("openSearch/clear");
  };

  const toggleSearch = () => {
    dispatch("openSearch/toggle");
    dispatch("openMenu/clear");
    if (storeSearch.length > 0) {
      dispatch("searchInput/clear");
    }
  };

  const getHeaderText = () => {
    if (chapter) {
		switch (chapter) {
			case "komponistgruppe":
			case "nyMusikks komponistgruppe":
				return "nyMusikks komponistgruppe";
			case "Composer Group":
			case "composer group":
			case "nyMusikks Composer Group":
			case "nyMusikks composer group":
				return "nyMusikks Composer Group";
			default:
				return "nyMusikk " + chapter[0].toUpperCase() + chapter.substring(1);
		}
	} else return "nyMusikk";
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  if (!data) return null;
  const menu = data[0].menuItems.nodes;
  return (
    <>
      {isMobile ? (
        <MobileHeader
          menuIsOpen={menuIsOpen}
          searchIsOpen={searchIsOpen}
          chapter={chapter}
          lang={lang}
          menu={menu}
          toggleMenu={toggleMenu}
          toggleSearch={toggleSearch}
        />
      ) : (
        <>
          <FixedMenu>
            <h1>{getHeaderText()}</h1>
            <Hamburger clickHandler={toggleMenu} open={menuIsOpen} />
          </FixedMenu>
          <TopMenuContainer>
            <LogoGridElement colStart={1} colEnd={3}>
              <A
                href={lang === "no" ? "/home" : "/en/home-2"}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={handleLogoClick}
              >
                {hover ? <LottieHoverLogo /> : <LottieLogo />}
              </A>
            </LogoGridElement>
            {isHome ? (
              <TopMenuGridElement
                colStart={{ default: 5, m: 5 }}
                colEnd={{ default: 14, m: 11 }}
                isVisible={!menuIsOpen && !searchIsOpen && isHome}
              >
                <StyledUl>
                  {menu.map((item, i) => {
                    return (
                      <li key={i}>
                        <A href={item.url}>{item.label}</A>
                      </li>
                    );
                  })}
                </StyledUl>
              </TopMenuGridElement>
            ) : null}
          </TopMenuContainer>
          <ButtonWrapper
            isFixed={menuIsOpen || searchIsOpen}
            shouldFadeIn={scrollHeight > 65}
          >
            <LanguageSwitcher />
            <SearchButton
              open={searchIsOpen}
              lang={lang}
              onClick={toggleSearch}
            />
          </ButtonWrapper>
          <Menu open={menuIsOpen} menu={menu} />
          <Search open={searchIsOpen} />
        </>
      )}
    </>
  );
}

export default Header;

const FixedMenu = styled.header`
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 40px;
  left: 40px;
  padding-bottom: 50px;
  height: calc(100vh - 40px);
  flex-direction: column;
  align-items: center;

  h1 {
    position: fixed;
    top: 50%;
    transform: rotate(-90deg);
    white-space: nowrap;
    ${(props) => props.theme.fonts.h5}
  }
`;

const TopMenuContainer = styled(Container)`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
`;

const LogoGridElement = styled(GridElement)`
  position: fixed;
  z-index: 1001;
  top: 40px;
  left: 40px;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
    ${(props) => props.theme.fonts.h4};
    margin-bottom: 4px;

    a {
      border-bottom: 1px solid transparent;
      padding-bottom: 1px;

      &:hover {
        ${(props) => props.theme.variables.transition}
        transition-duration: 250ms;
        border-bottom: 1.2px solid ${(props) => props.theme.colors.dark};
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  position: ${(props) => (props.isFixed ? "fixed" : "absolute")};
  z-index: 1002;
  display: flex;
  top: 40px;
  right: 40px;
  opacity: ${(props) => (props.shouldFadeIn ? 0 : 1)};

  ${(props) =>
    props.isFixed &&
    css`
      ${(props) => props.theme.variables.transition}
      transition-duration: 380ms;
      transition-delay: 385ms;
      transition-property: opacity;
      opacity: 1;
    `}

  ${Media.greaterThan("l")`
        right: calc((100vw - 1730px) / 2);
    `}
`;

const TopMenuGridElement = styled(GridElement)`
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  ${(props) => props.theme.variables.transition}
  transition-duration: 480ms;
`;
