import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import FadeIn from 'components/partials/FadeIn';

import Media from 'css/Media';

export const videoQuery = pageType => `
    ... on ${pageType}_Modules_Builder_VideoModule {
        fieldGroupName
        text
        link {
            title
            url
        }
    }
`

export default function VideoModule(props) {
    const { data } = props;
    return (
        <StyledContainer>
            <StyledGridElement
                colStart={{default: 2, s: 9}}
                colEnd={{default: 13, s: 24}}
                rowStart={1}
            >
                <StyledFadeIn>
                    <iframe src={data.link.url} title={data.link.title}/>
                </StyledFadeIn>
            </StyledGridElement>
            <GridElement
                colStart={{default: 2, s: 9}}
                colEnd={{default: 13, s: 24}}
                rowStart={2}
            >
                <StyledP>{data.text}</StyledP>
            </GridElement>
        </StyledContainer>
    )
}

const StyledContainer = styled(Container)`
    padding-bottom: 32px;

    ${Media.greaterThan('s')`
        padding-bottom: 64px;
    `}
`

const StyledGridElement = styled(GridElement)`
   
`

const StyledFadeIn = styled(FadeIn)`
    margin-bottom: 8px;
    padding-bottom: 56.25%;
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.colors.alt};
    }

    ${Media.greaterThan('s')`
        margin-bottom: 23px;
    `}
`

const StyledP = styled.p`
    ${props => props.theme.fonts.h8}
    text-transform: none;
    color: #707070;

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h7}
    `}
`