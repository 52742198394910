import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';
import useIsMobile from 'hooks/useIsMobile';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import Image from 'components/Image';
import FadeIn from 'components/partials/FadeIn';

import ArrowSmall from 'icons/ArrowSmall';
import ArrowBig from 'icons/ArrowBig';

import Media from 'css/Media';

export const imageCarouselQuery = pageType => `
    ... on ${pageType}_Modules_Builder_ImageCarouselModule {
        fieldGroupName
        images {
            caption
            fieldGroupName
            image {
                mediaItemUrl
            }
        }
    }
`

export default function ImageCarouselModule(props) {
    const [elementWidth, setElementWidth] = useState(0);
    const [columnWidth, setColumnWidth] = useState(0);
    const [gapWidth, setGapWidth] = useState(0);
    const swiperRef = React.createRef();
    let [imageSwiper, setImageSwiper] = useState(null);
    let isMobile = useIsMobile();
    
    // Set width using proxies since swiper uses css flex and we use grid
    useEffect(() => {
        const handleResize = () => {
            let proxy = document.getElementById('proxy');
            let column = document.getElementById('column');
            let gap = document.getElementById('gap');
            setElementWidth(proxy.getBoundingClientRect().width);
            setColumnWidth(column.getBoundingClientRect().width);
            setGapWidth(gap.getBoundingClientRect().width);
        }
        handleResize();
        window.addEventListener('resize',handleResize)

        return() => {
            window.removeEventListener('resize',handleResize)
        }
    },[]);

    // setup and initiate swiper
    useEffect(() => {
        const imageSwiper = new Swiper(swiperRef.current, {
            slidesPerView: 'auto',
            allowTouchMove: isMobile ? true : false,
            init: false,
            mousewheel: true,
            keyboard: true,
            observer: true,
            observeParents: true
        })
        setImageSwiper(imageSwiper);
    },[isMobile]);

    useEffect(() => {
        if(imageSwiper) {
            imageSwiper.activeIndex = 0;
            imageSwiper.init();
        }
    },[imageSwiper])

    // handle navigation
    const handleClick = dir => {
        if(dir === 'next') {
            imageSwiper.slideNext();
        } else {
            imageSwiper.slidePrev();
        }
    }
    const { data } = props;
    return (
        <Wrapper width={elementWidth} col={columnWidth} gap={gapWidth}>
            <StyledContainer>
                <NavGridElement
                    colStart={{default: 2, s: 5}}
                    colEnd={{default: 13, s: 24}}
                    rowStart={1}
                >
                    <ArrowWrapper 
                        onClick={() => handleClick('prev')}
                    >
                        {isMobile ? <ArrowSmall /> : <ArrowBig />}
                    </ArrowWrapper>
                    <ArrowWrapper
                        onClick={() => handleClick('next')}
                        next
                    >
                        {isMobile ? <ArrowSmall /> : <ArrowBig />}
                    </ArrowWrapper>
                </NavGridElement>
                <GridElement 
                    colStart={{default: 2, s: 5}}
                    colEnd={{default: 11, s: 12}}
                    rowStart={3}
                    id="proxy"
                />
                <GridElement 
                    colStart={{default: 2, s: 3}}
                    colEnd={{default: 3, s: 4}}
                    rowStart={3}
                    id="column"
                />
                <GridElement 
                    colStart={{default: 1, s: 2}}
                    colEnd={{default: 2, s: 3}}
                    rowStart={3}
                    id="gap"
                />
            </StyledContainer>
            <div className="swiper-container" ref={swiperRef}>
                <div className="swiper-wrapper">
                    {data.images.map((item, idx) => {
                        return (
                            <div className="swiper-slide" key={idx}>
                                <FadeIn>
                                    <StyledImage src={item.image.mediaItemUrl} key={idx} />
                                </FadeIn>
                                {item.caption ? <StyledP>{item.caption}</StyledP> : null}
                            </div>
                        )
                    })}
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 100px;

    ${Media.greaterThan('s')`
        margin-bottom: 150px;
    `}

    .swiper-container {
        display: flex;
        overflow-x: hidden;
        margin-left: ${props => props.gap}px;
        
        .swiper-wrapper {
            display: flex;
            align-items: flex-start;
            transition: all .42 ease !important;
            
            .swiper-slide {
                display: flex;
                flex-direction: column;
                width: ${props => props.width}px;
                margin-right: ${props => props.gap}px;
            
                img {
                    max-width: none;
                    width: 100%;
                }
            }
        }
        
        ${Media.greaterThan('s')`
            margin-left: ${props => `calc(30px + ${(props.col * 2) + (props.gap*2)}px)`};
        `}

        ${Media.greaterThan('l')`
            margin-left: ${props => `calc((100vw - 1800px) / 2 + 30px + ${(props.col * 2) + (props.gap*2)}px)`};
        `}
    }
`

const StyledContainer = styled(Container)`
    #proxy, #gap {
        height: 0;
    }
`

const NavGridElement = styled(GridElement)`
    display: flex;
    padding-bottom: 16px;
    
    ${Media.greaterThan('s')`
        padding-bottom: 30px;
    `}
`

const ArrowWrapper = styled.div`
    cursor: pointer;
    user-select: none;
    display: flex;
    align-content: center;
    font-size: 16px;

    ${props => props.next && css`
        justify-content: flex-end;
        transform: rotate(180deg);
        margin-left: 16px;
    `}

    svg:hover {
        ${props => props.theme.variables.transition}
        transition-duration: 260ms;
        fill: ${props => props.theme.colors.alt};
    }

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h5};
    `}
`

const StyledImage = styled(Image)`
    margin-bottom: 8px;
    
    ${Media.greaterThan('s')`
        margin-bottom: 23px;
    `}
`

const StyledP = styled.p`
    ${props => props.theme.fonts.h8}
    text-transform: none;
    color: ${props => props.theme.colors.alt};

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h7}
    `}
`