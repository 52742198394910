import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Hits from './Hits'
import FilterBar from 'components/partials/FilterBar/FilterBar';
import searchClient from './searchClient'
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';

import PageLoader from 'components/partials/PageLoader';
import Container from 'components/Container';
import GridElement from 'components/GridElement';

import SearchIcon from 'icons/SearchIcon';
import CloseIcon from 'icons/CloseIcon';
import Media from 'css/Media';

const AlgoliaSearch = props => {
    let storeSearch = useSelector(state => state.searchInput);
    const [ value, setValue ] = useState('');

    useEffect(() => {
        if(storeSearch && storeSearch.length > 0) {
            setValue(storeSearch)
        }
    },[storeSearch])

    const handleOnChange = e => {
        let val = e.target.value;
        setValue(val)
    }

    if(!props.open) return null;
    return (
        <StyledInstantSearch
            searchClient={searchClient}
            indexName={process.env.REACT_APP_ALGOLIA_INDEX}
        >
            <StyledContainer>
                <SearchGridElement
                    rowStart={1}
                    colStart={{default: 2, s: 9}}
                    colEnd={{default: 13, s: 24}}
                >
                    <FilterBar>
                        <StyledSearchBox 
                            defaultRefinement={storeSearch}
                            showLoadingIndicator={true}
                            translations={props.lang === 'no' ? 
                                {placeholder: 'Søk etter komponist, verk, musikere, sted, avdeling'} : 
                                {placeholder: 'Search for composer, work, musicians, venue, chapter'}}
                            submit={value.length ? null : <StyledSearch />}
                            reset={<StyledClose />}
                            loadingIndicator={<PageLoader />}
                            onChange={e => handleOnChange(e)}
                            onReset={() => setValue('')}
                            autoFocus={props.open}
                        />
                    </FilterBar>
                </SearchGridElement>
                <HitsGridElement
                    rowStart={2}
                    colStart={{default: 2, s: 1}}
                    colEnd={{default: 13, s: 25}}    
                >
                    <Hits lang={props.lang}/>
                </HitsGridElement>
            </StyledContainer>
        </StyledInstantSearch>
    )
}

export default AlgoliaSearch;


const StyledContainer = styled(Container)`
    padding: 78px 0 32px;
    overflow-y: visible;

    ${Media.greaterThan('s')`
        padding: 0 30px 173px!important;
    `}
`

const StyledSearch = styled(SearchIcon)`
    width: 22px;
    height: 22px;
`

const StyledClose = styled(CloseIcon)`
    width: 22px;
    height: 22px;
`

const StyledInstantSearch = styled(InstantSearch)`
    width: 100%;
`

const SearchGridElement = styled(GridElement)`
    background-color: ${props => props.theme.colors.negative};
    padding-bottom: 32px;

    ${Media.greaterThan('s')`
        padding-bottom: 78px;
    `}
`

const StyledSearchBox = styled(SearchBox)`
    width: 100%;
    display: flex;
    cursor: pointer;

    form {
        position: relative;
        width: 100%;

        &:focus {
            display: none;
        }
        
        input {
            width: calc(100% - 22px);
            border: none;
            ${props => props.theme.fonts.h7}
            padding: 0;

            ::placeholder {
                color: ${props => props.theme.colors.text};
                opacity: 1;
            }
            
            ${Media.greaterThan('s')`
                ${props => props.theme.fonts.h4}
            `}
        }
        button {
            margin: 0;
        }
    }
`

const HitsGridElement = styled(GridElement)`
    
`