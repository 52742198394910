import { generateMedia } from "styled-media-query";
import _ from 'lodash';

const BreakPoints = {
    s: '900px',
    m: '1200px',
    l: '1800px'
}

const Media = generateMedia(BreakPoints);

class ScreenWidth {
    static lessThan(size) {
        if (!_.has(BreakPoints, size)) {
            throw new Error('Given size ' + size + ' is not a valid breakpoint');
        }

        let windowWidth = window.innerWidth;
        let breakPointWidth = parseInt(BreakPoints[size], 10);

        return windowWidth <= breakPointWidth;
    }
    static between(min, max) {
        if (!_.has(BreakPoints, min, max)) {
            throw new Error('Given size ' + min + ' and/or ' + max + ' is not a valid breakpoint');
        }
        let windowWidth = window.innerWidth;
        let minWidth = parseInt(BreakPoints[min], 10);
        let maxWidth = parseInt(BreakPoints[max], 10);

        return windowWidth >= minWidth && windowWidth <= maxWidth;
    }
    static greaterThan(size) {
        if (!_.has(BreakPoints, size)) {
            throw new Error('Given size ' + size + ' is not a valid breakpoint');
        }

        let windowWidth = window.innerWidth;
        let breakPointWidth = parseInt(BreakPoints[size], 10);

        return windowWidth >= breakPointWidth;
    }
}

export default Media;
export { BreakPoints, ScreenWidth };