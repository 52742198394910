import React, { useEffect } from "react";
import useRequest from "kb-gql";
import { useSelector } from "react-redux";
import { dispatch } from "../redux";

import PageTitleModule from "components/modules/PageTitleModule";
import ThumbnailList from "components/partials/ThumbnailList";
import ArticleThumbnail from "components/partials/ArticleThumbnail";
import PageLoader from "components/partials/PageLoader";

const pageQuery = (lang) => `
    chapters(last: 20, where: {language: ${lang}}) {
        nodes {
            title
            uri
            thumbnailData {
                featuredImage {
                    mediaItemUrl
                }
            }
        }
    }
`;

function ChaptersPage() {
  const lang = useSelector((state) => state.lang);
  const allChapters = useRequest(
    pageQuery(lang.toUpperCase()),
    "allChapters",
    "nodes"
  );

  useEffect(() => {
    if (allChapters) {
      dispatch("currentView/update", {
        view: "chapters",
        no: "/avdelinger",
        en: "/en/chapters",
      });
    }
  }, [allChapters]);

  const sortNames = (a, b) => {
    let nameA =
      a.title.slice(0, 9) === "nyMusikks" ? a.title.slice(10) : a.title.slice(9);
    let nameB =
      b.title.slice(0, 9) === "nyMusikks" ? b.title.slice(10) : b.title.slice(9);
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  if (!allChapters) return <PageLoader />;
  return (
    <section>
      <PageTitleModule
        data={lang === "no" ? { title: "Avdelinger" } : { title: "Chapters" }}
      />
      {allChapters.length > 0 ? (
        <ThumbnailList>
          {allChapters.sort(sortNames).map((item, i) => {
            return (
              <ArticleThumbnail
                key={i}
                idx={i}
                url={item.uri}
                image={item.thumbnailData.featuredImage.mediaItemUrl}
                title={item.title}
                chapter
              />
            );
          })}
        </ThumbnailList>
      ) : null}
    </section>
  );
}

export default ChaptersPage;
