import React from 'react';
import useRequest from 'kb-gql';
import { useSelector } from 'react-redux';

import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';
import useIsMobile from 'hooks/useIsMobile';

const siteSettingsQuery = `
    acfOptionsSiteSettings {
        site_settings {
            fieldGroupName
            orgNumber
            orgNumberEn
            newsletterSignupLink {
                target
                title
                url
            }
            newsletterSignupLinkEn {
                target
                title
                url
            }
            privacyLink {
                target
                title
                url
            }
            privacyLinkEn {
                target
                title
                url
            }
        }
    }
  
`

const footerQuery = `
	acfOptionsFooterOptions{
		footer_content {
			footerItems {
				title
				content {
					link {
						target
						title
						url
					}
					text
					type
				}
			}
			footerItemsEn {
				title
				content {
					link {
						target
						title
						url
					}
					text
					type
				}
			}
		}
	}
`

const Footer = props => {
    const footerData = useRequest(footerQuery, 'footerOptions', 'footer_content');
    const siteSettings = useRequest(siteSettingsQuery, 'siteSettings', 'site_settings');
    const lang = useSelector(state => state.lang);

    return useIsMobile() ? 
        <MobileFooter 
            footerData={footerData}
            siteSettings={siteSettings} 
            isVisible={props.isVisible} 
            lang={lang} 
        /> 
        : 
        <DesktopFooter 
            footerData={footerData} 
            siteSettings={siteSettings} 
            isVisible={props.isVisible} 
            lang={lang} 
        />;
}

export default Footer;