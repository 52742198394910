import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { uniq } from "lodash";
import moment from "moment-mini";
import useRequest from "kb-gql";
import { useSelector } from "react-redux";

import useIsMobile from "hooks/useIsMobile";
import { dispatch } from "../../redux";

import Container from "components/Container";
import GridElement from "components/GridElement";
import FilterBar from "components/partials/FilterBar/FilterBar";
import PageTitleModule from "components/modules/PageTitleModule";
import DateInput from "components/partials/FilterBar/DateInput/DateInput";
import SelectItem from "components/partials/FilterBar/Select/SelectItem";
import MobileFilter from "./MobileFilter";

import ThumbnailList from "components/partials/ThumbnailList";
import ArticleThumbnail from "components/partials/ArticleThumbnail";
import PageLoader from "components/partials/PageLoader";
import useScrollToBottom from "hooks/useScrollToBottom";

import Media from "css/Media";

const dateQuery = (day, month, year) => `
    dateQuery: {
      column: DATE, 
      before: {
        day: ${day}, 
        month: ${month}, 
        year: ${year}
      }, 
      inclusive: true
    }, 
  
`;

const termsQuery = (terms) => `
  taxQuery: {
    relation: OR, 
    taxArray: {
      taxonomy: CHAPTERTAG, 
      terms: [${terms.map((t) => `"${t}"`).join(",")}],
      field: NAME
    }
  }, 
`;

const newsQuery = (lang, year, month, day, terms) => `
  news(
    first: 100,
    where: {
      ${dateQuery(day, month, year)}
      language: ${lang},
      ${terms ? termsQuery(terms) : ""}
      orderby: {field: DATE, order: DESC}
    }) {
    edges {
      node {
        title
        uri
        date
        metaTags {
          nodes {
            name
          }
        }
        chaptersTags(last:1) {
          nodes {
            name
          }
        }
        thumbnailData {
          description
          featuredImage {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

const chaptersQuery = (lang) => `
  chaptersTags(last: 20, where: {language: ${lang}}) {
    nodes {
      name
    }
  }
`;

function NewsList({ day, year, month, lang, isBlurry, selectedChapters, handleItems, elementsToLoad }) {
  const [selectedNews, setSelectedNews] = useState([]);
  const data = useRequest(
    newsQuery(lang.toUpperCase(), year, month, day, selectedChapters || null),
    "news" +
      day +
      year +
      month +
      lang +
      (selectedChapters && selectedChapters.length
        ? selectedChapters
            .map((t) => t.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, ""))
            .join("")
        : ""),
    "edges"
  );

  useEffect(() => {
    //set data to array of objects easier to filter and sort later
    if (data) {
      let arr = [];
      data.forEach((item) => {
        arr = [
          ...arr,
          {
            title: item.node.title,
            date: item.node.date,
            image: item.node.thumbnailData.featuredImage.mediaItemUrl,
            url: item.node.uri,
            description: item.node.thumbnailData.description,
            chapter: item.node.chaptersTags.nodes[0]?.name,
          },
        ];
      });
      handleItems(arr);
      setSelectedNews(arr.slice(0, elementsToLoad));
    }
  }, [data, elementsToLoad, handleItems]);

  //set currentView
  useEffect(() => {
    if (data)
		dispatch("currentView/update", {
			view: "news",
			no: "/nyheter",
			en: "/en/news",
		});
	return () => {
		dispatch("currentView/clear");
	};
  }, [data]);

  if (selectedNews.length < 1) return null;
  return (
    <StyledThumbnailList mobileFullWidth isBlurry={isBlurry}>
      {selectedNews.map((item, idx) => {
        return (
          <ArticleThumbnail
            key={idx}
            url={item.url}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        );
      })}
    </StyledThumbnailList>
  );
}

function NewsFilterPage() {
  //array of all chapters, used to present options in filter dropdown
  const [chapterOptions, setChapterOptions] = useState(false);
  //date used to display selected date from datepicker. Defaults to today
  const [selectedDate, setSelectedDate] = useState(new Date());
  //array of selected chapters. Null if nothing is selected
  const [selectedChapters, setSelectedChapters] = useState(null);

  //page specific variables
  const isMobile = useIsMobile();
  const lang = useSelector((state) => state.lang);
  const isBlurry = useSelector((state) => state.backgroundOpacity);

  const programListRef = useRef();
  const [items, setItems] = useState([]);
  const [loadedElements, setLoadedElements] = useState(10);

  //initial request to fetch all chapters
  const chapterData = useRequest(
    chaptersQuery(lang.toUpperCase()),
    "chapters",
    "nodes"
  );

  //For initial news
  const [year, setYear] = useState(parseInt(moment().format("YYYY")));
  const [month, setMonth] = useState(parseInt(moment().format("MM")));
  const [day, setDay] = useState(parseInt(moment().format("DD")));

  //set chapter options once after fetching
  useEffect(() => {
    if (chapterData && chapterData.length > 0) {
      let arr = [];
      chapterData.map((item) => arr.push(item.name));
      let uniqueObjects = uniq(arr);
      let sorted = uniqueObjects.sort();
      setChapterOptions(sorted);
    }
  }, [chapterData]);

  useScrollToBottom(programListRef, () => _onHandleSetLoadedElements());

  const setVisibility = (bool) => {
    if (bool === true) {
      dispatch("isBlurry/setBlurry");
    } else dispatch("isBlurry/clear");
  };

  const _onHandleSetLoadedElements = () => {
    if(items.length > 0 && loadedElements <= items.length)
    {
      setLoadedElements(loadedElements + 10);
    }
  }

  //onChange handlers
  const handleDateChange = (date) => {
    if (date === null || date === undefined) {
      setSelectedDate(new Date());
      setYear(parseInt(moment().format("YYYY")));
      setMonth(parseInt(moment().format("MM")));
      setDay(parseInt(moment().format("DD")));
    } else {
      setYear(parseInt(moment(date).format("YYYY")));
      setMonth(parseInt(moment(date).format("MM")));
      setDay(parseInt(moment(date).format("DD")));
      setSelectedDate(date);
    }
  };

  const handleChapterChange = (arr) => {
    if (arr.length) {
      setSelectedChapters(arr);
    } else setSelectedChapters(null);
  };

  if (!day && !year && !month && !lang) return <PageLoader />;
  return (
    <section>
      <PageTitleModule
        data={lang === "no" ? { title: "Nyheter" } : { title: "News" }}
      />
      <StyledContainer>
        <GridElement
          colStart={{ default: 2, s: 9 }}
          colEnd={{ default: 13, s: 24 }}
        >
          {!isMobile ? (
            <FilterBar>
              <DateInput
                setVisibility={setVisibility}
                handleDateChange={handleDateChange}
                date={selectedDate}
                maxDate={new Date()}
                showYear
                reverse
              />
              <StyledSelectItem
                name={lang === "no" ? "Avdeling" : "Chapter"}
                arr={chapterOptions}
                setVisibility={setVisibility}
                handleChange={handleChapterChange}
                reverse
              />
            </FilterBar>
          ) : (
            <MobileFilter
              date={selectedDate}
              chapters={chapterOptions}
              maxDate={new Date()}
              setVisibility={setVisibility}
              handleDateChange={handleDateChange}
              handleChapterChange={handleChapterChange}
              lang={lang}
            />
          )}
        </GridElement>
      </StyledContainer>
      <section ref={programListRef}>
      <NewsList
        day={day}
        year={year}
        month={month}
        lang={lang}
        isBlurry={isBlurry}
        handleItems={setItems}
        selectedChapters={selectedChapters}
        elementsToLoad={loadedElements}
      />
      </section>
    </section>
  );
}

export default NewsFilterPage;

const StyledContainer = styled(Container)`
  padding: 78px 0 48px;

  ${Media.greaterThan("s")`
        padding: 0 30px 173px;
    `}
`;

const StyledThumbnailList = styled(ThumbnailList)`
  opacity: ${(props) => (props.isBlurry ? 0.05 : 1)};
  ${(props) => props.theme.variables.transition}
  transition-property: opacity;
  transition-duration: 350ms;
`;
const StyledSelectItem = styled(SelectItem)`
  margin-right: 32px;
`;
