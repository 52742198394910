import React, { useState } from "react";
import styled, { css } from "styled-components";

import Container from "components/Container";
import GridElement from "components/GridElement";
import MobileDateInput from "components/partials/FilterBar/DateInput/MobileDateInput";
import MobileSelectItem from "../../components/partials/FilterBar/Select/MobileSelectItem";
import PlusIcon from "icons/PlusIcon";

const MobileFilter = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <Container grid>
        <StyledGridElement
          colStart={{ default: 1, s: 1 }}
          colEnd={{ default: 14, s: 24 }}
        >
          <EntireBlock>
            <ItemTitleWrapper onClick={() => setOpen(!open)}>
              <h5>
                {props.lang === "no" ? "Filtrer program" : "Filter program"}
              </h5>
              {open ? <PlusIcon /> : <PlusIcon />}
            </ItemTitleWrapper>
            <SubItem isVisible={open ? 1 : 0}>
              <MobileDateInput
                setVisibility={props.setVisibility}
                handleDateChange={props.handleDateChange}
                date={props.date}
                showYearDropdown
              />
              {props.cities ? (
                <MobileSelectItem
                  arr={props.cities}
                  handleChange={props.handleCityChange}
                  name={props.lang === "no" ? "Sted" : "City"}
                  setVisibility={props.setVisibility}
                />
              ) : null}
              {props.chapters ? (
                <MobileSelectItem
                  arr={props.chapters}
                  handleChange={props.handleChapterChange}
                  name={props.lang === "no" ? "Avdeling" : "Chapter"}
                  setVisibility={props.setVisibility}
                />
              ) : null}
              {props.types ? (
                <MobileSelectItem
                  arr={props.types}
                  handleChange={props.handleTypeChange}
                  name={props.lang === "no" ? "Type" : "Type of event"}
                  setVisibility={props.setVisibility}
                />
              ) : null}
            </SubItem>
          </EntireBlock>
        </StyledGridElement>
      </Container>
    </Wrapper>
  );
};

export default MobileFilter;

const Wrapper = styled.section`
  user-select: none;
`;

const ItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid #000;

  svg {
    height: 16px;
  }

  h5 {
    ${(props) => props.theme.fonts.h7}
  }

  p {
    ${(props) => props.theme.fonts.h7}
    font-size: 32px;
  }

  ${(props) =>
    props.open &&
    css`
      svg {
        transform: rotate(360deg);
      }
    `}
`;

const StyledGridElement = styled(GridElement)`
  border-top: 1px solid #000;
`;

const EntireBlock = styled.div``;

const SubItem = styled.div`
  display: ${(props) => (props.isVisible ? "block" : "none")};

  > div:first-child {
    padding: 16px 0 !important;
  }
`;