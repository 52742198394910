import React from 'react';

export default props => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" className={props.className}>
            <g id="Group_3" data-name="Group 3" transform="translate(976.171 814.685) rotate(180)">
                <path id="Path_3" data-name="Path 3" d="M975.921,809.735l-4.61,4.722a.733.733,0,0,1-.528.228.671.671,0,0,1-.5-.2.7.7,0,0,1-.2-.513.848.848,0,0,1,.049-.285.582.582,0,0,1,.147-.228l1.233-1.311,3.02-2.787.161.394-2.377.174h-8.435a.675.675,0,0,1-.518-.211.819.819,0,0,1,0-1.064.675.675,0,0,1,.518-.211h8.435l2.377.174-.161.4-3.02-2.8-1.233-1.311a.583.583,0,0,1-.147-.228.848.848,0,0,1-.049-.285.7.7,0,0,1,.2-.513.671.671,0,0,1,.5-.2.733.733,0,0,1,.528.228l4.61,4.722a.729.729,0,0,1,0,1.1Z" transform="translate(0 0)"/>
            </g>
        </svg>
    );
}