import React from 'react';

const Chevron = props => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <path d="M13 7L7 1L1 7" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="square" />
        </svg>
    )
}

export default Chevron;