import React from "react";
import styled from "styled-components";

import Image from "components/Image";
import Overlay from "components/partials/ImageOverlay";
import FadeIn from "components/partials/FadeIn";
import ThumbnailList from "components/partials/ThumbnailList";

import Media from "css/Media";

export const PDFLinkListQuery = (pageType) => `
    ... on ${pageType}_Modules_Builder_PdfLinkListModule {
      fieldGroupName
    }
`;

export default function PDFLinkListModule(props) {
  if (!props.reports) return null;

  const sortNames = (a, b) => {
    let yearA = a.annual_reports.title.slice(a.annual_reports.title.length - 4);
    let yearB = b.annual_reports.title.slice(b.annual_reports.title.length - 4);
    if (yearA > yearB) {
      return -1;
    }
    if (yearA > yearB) {
      return 1;
    }
    return 0;
  };

  return (
    <Wrapper>
      <ThumbnailList>
        {props.reports.sort(sortNames).map((item, idx) => {
          const report = item.annual_reports;
          return (
            <PDFItem key={idx}>
              <FadeIn>
                <StyledOverlay>
                  <a href={report.link.mediaItemUrl} download>
                    <Image src={report.image.mediaItemUrl} />
                  </a>
                </StyledOverlay>
              </FadeIn>
              <p>{report.title}</p>
              <a href={report.link.mediaItemUrl} download>
                ↓ <span>Last ned PDF</span>
              </a>
            </PDFItem>
          );
        })}
      </ThumbnailList>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 50px 0;

  ${Media.greaterThan("s")`
        margin: 100px 0;
    `}
`;

const PDFItem = styled.div`
  text-align: left;
  margin-bottom: 32px;

  img {
    max-width: none;
    width: 100%;
  }

  p {
    ${(props) => props.theme.fonts.h7}
    margin-bottom: 8px;
  }

  span {
    ${(props) => props.theme.fonts.h8}
    text-decoration: none;
    ${(props) => props.theme.variables.transition}
    transition-duration: 250ms;

    ${Media.greaterThan("s")`
            border-color: transparent;
        `}

    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.colors.dark};
    }
  }

  ${Media.greaterThan("s")`
        margin-bottom: 112px;
        p {
            ${(props) => props.theme.fonts.h5}
            margin-bottom: 16px;
        }

        span {
            ${(props) => props.theme.fonts.h7}
        }
    `}
`;

const StyledOverlay = styled(Overlay)`
  margin-bottom: 16px;
`;
