import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { format, parse, endOfYear, startOfYear } from "date-fns";
import { uniq } from "lodash";
import Container from "components/Container";
import GridElement from "components/GridElement";
import PageTitleModule from "components/modules/PageTitleModule";
import ThumbnailList from "components/partials/ThumbnailList";
import EventThumbnail from "components/partials/EventThumbnail";
import PageLoader from "components/partials/PageLoader";
import ProgramFilter from "./ProgramFilter";
import { setItemParams } from "./functions";
import useScrollToBottom from "hooks/useScrollToBottom";

import Media from "css/Media";


//Additional taxQuery to be run if one or more chapters selected from filter
const selectedChaptersQuery = (chapters) => `
  {
    taxonomy: CHAPTERTAG, 
    terms: [${chapters.map((t) => `"${t}"`).join(",")}],
    field: NAME
  }, 
`;

//Additional taxQuery to be run if one or more performanceTypes selected from filter
const selectedTypesQuery = (types) => `
  {
    taxonomy: PERFORMANCETYPETAG, 
    terms: [${types.map((t) => `"${t}"`).join(",")}],
    field: NAME
  }
`;

//taxQuery to be run if either chapters or performanceTypes selected from filter
const taxQuery = (chapters, types) => `
  taxQuery: {
    relation: AND, 
    taxArray: [
      ${chapters.length > 0 ? selectedChaptersQuery(chapters) : ""}
      ${types.length > 0 ? selectedTypesQuery(types) : ""}
    ]
  },
`;

//Additional metaQuery. By default it filters by todays date, filters from selected date on user interaction
const dateQuery = (dateStr, type) => {
	const dateStrEnd = format(endOfYear(parse(dateStr, 'yyyyMMdd', new Date())), 'yyyyMMdd');
	return`
		{
			compare: GREATER_THAN_OR_EQUAL_TO,
			key: "${type === "events" ? "event_date" : "date_start"}",
			type: DATE,
			value: "${dateStr}"
		},
		{
			compare: LESS_THAN_OR_EQUAL_TO,
			key: "${type === "events" ? "event_date" : "date_start"}",
			type: DATE,
			value: "${dateStrEnd}"
		},
	`
};

//Additional metaQuery to be run if venue is selected from filters
const venueQuery = (venues) => `
  {
    key: "chapter",
    value: ${venues.map((t) => `"${t}"`).join(",")},
  },
`;

//metaQuery to be run if venues is added/removed or when dateStr changes
const metaValueQuery = (venues, dateStr, type) => {
	return`
		metaQuery: {
			metaArray: [
			${dateStr ? dateQuery(dateStr, type) : ""}
			${venues.length > 0 ? venueQuery(venues) : ""}
			]
		},
		`
	};
//Build query either targeting "events" or "festivals", adding on query parts if present
const build = (type, lang, dateStr, chapters, venues, types) => `
query MyQuery {
  ${type}(first: 100, where: {
    language: ${lang}, 
    ${chapters.length > 0 || types.length > 0 ? taxQuery(chapters, types) : ""}
    ${venues.length > 0 || dateStr ? metaValueQuery(venues, dateStr, type) : ""}
  }) {
    edges {
      node {
        uri
        title
        ${
          type === "events"
            ? `
          eventData {
            chapter
            eventDate
            eventStart
            venue
          }`
            : `festivalData {
            chapter
            dateStart
            dateEnd
            venue
          }`
        }
        
        thumbnailData {
          featuredImage {
            mediaItemUrl
          }
        }
        metaTags(last: 3) {
          nodes {
            uri
            name
          }
        }
        chaptersTags(last: 1) {
          nodes {
            name
          }
        }
        performanceTypeTags(last: 5) {
          nodes {
            name
          }
        }
      }
    }
  }
}`;
//Query to fetch all performanceTypeTags used for creating dropdown list
const performanceTagsQuery = (lang) => `
  performanceTypeTags(last: 20, where: {language: ${lang}}) {
    nodes {
      name
    }
  }
`;
//Query to fetch all chaptersTags used for creating dropdown list
const chaptersQuery = (lang) => `
  chaptersTags(last: 20, where: {language: ${lang}}) {
    nodes {
      name
    }
}
`;

//We fetch events and festivals using a vanilla query, to avoid conflicts in query key in kb-gql (where queries need unique keys on each request)
async function getBuildData(
  type,
  lang,
  dateStr,
  selectedChapters,
  selectedVenues,
  selectedTypes
) {
  const eventResp = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: build(
        type,
        lang,
        dateStr,
        selectedChapters || "",
        selectedVenues || "",
        selectedTypes || ""
      ),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  return eventResp;
}

function ProgramList({
  dateStr,
  lang,
  isBlurry,
  handleItems,
  selectedVenues,
  selectedChapters,
  selectedTypes,
  elementsToLoad,
  setCities,
  ...props
}) {
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState();
  const [festivals, setFestivals] = useState();
  //Fetch events and festivals and update state
  useEffect(() => {
    if (dateStr || selectedVenues || selectedChapters || selectedTypes) {
      getBuildData(
        "events",
        lang.toUpperCase(),
        dateStr,
        selectedChapters,
        selectedVenues,
        selectedTypes,
      ).then((res) => setEvents(res));
      getBuildData(
        "festivals",
        lang.toUpperCase(),
        dateStr,
        selectedChapters,
        selectedVenues,
        selectedTypes,
      ).then((res) => setFestivals(res));
    }
	return () => {
		dispatch("currentView/clear");
	};
  }, [dateStr, selectedVenues, selectedChapters, selectedTypes, lang]);
  //Gather data from events and festivals, sort ascending and set state. Also, we trigge props.handleItems
  useEffect(() => {
	const getCities = (data) => {
		const arr = [];
		data.forEach((item) => {
			if (item.isEvent) {
				arr.push(item.data.eventData.chapter);
			} else {
				arr.push(item.data.festivalData.chapter);
			}
		});
		const uniqueObjects = uniq(arr);
		const sorted = uniqueObjects.sort();
		return sorted;
	};

    if (events && festivals) {
      let allItems = [
        ...setItemParams("events", events),
        ...setItemParams("festivals", festivals),
      ];
      allItems.sort((a, b) => b.date - a.date).reverse();
	  setCities(getCities(allItems));
	  allItems = allItems.slice(0, elementsToLoad).map((item) => {
	    return item;
	  });
      setItems(allItems);
      handleItems(allItems);
    }
  }, [events, festivals, elementsToLoad, handleItems]);

  if (items.length < 1) return null;

  return (
      <StyledThumbnailList mobileFullWidth isBlurry={isBlurry}>
        {items.map((event) => {
          return (
            <EventThumbnail
              type={event.isEvent ? "event" : "festival"}
              item={event.data}
              key={event.date+event.data.title}
            />
          );
        })}
      </StyledThumbnailList>
  );
}

const ProgramPage = (props) => {
  //page specific variables
  const lang = useSelector((state) => state.lang);
  const isBlurry = useSelector((state) => state.backgroundOpacity);
  const [dateStr, setDateStr] = useState(format(new Date(), "yyyyMMdd"));
  const performanceTags = useRequest(
    performanceTagsQuery(lang.toUpperCase()),
    "performanceTags",
    "nodes"
  );
  const chapters = useRequest(
    chaptersQuery(lang.toUpperCase()),
    "chapters",
    "nodes"
  );

  //arrays containing user selected params for filtering
  const [items, setItems] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState(null);
  const [selectedChapters, setSelectedChapters] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(null);
  const [loadedElements, setLoadedElements] = useState(10); 
  const programListRef = useRef();
  //set currentView
  useEffect(() => {
    dispatch("currentView/update", {
      view: "program",
      no: "/program",
      en: "/en/program-en",
    });
  }, []);

  useScrollToBottom(programListRef, () => _onHandleSetLoadedElements());

  const _onHandleSetLoadedElements = () => {
    if(items.length > 0 && loadedElements <= items.length){
      setLoadedElements(loadedElements + 10);
    }
  }

  //set global opacity overlay to highlight dropdown options
  const setVisibility = (bool) => {
    if (bool === true) {
      dispatch("isBlurry/setBlurry");
    } else dispatch("isBlurry/clear");
  };

  //input onChange handlers
  const handleDateChange = (date) => {
    if (!date) {
      setDateStr(format(new Date(), "yyyyMMdd"));
    } else {
      setDateStr(format(date, "yyyyMMdd"));
    }
  };

  const handleCityChange = (arr) => {
    if (arr.length < 1) {
      setSelectedVenues(null);
    } else setSelectedVenues(arr);
  };

  const handleChapterChange = (arr) => {
    if (arr.length < 1) {
      setSelectedChapters(null);
    } else setSelectedChapters(arr);
  };

  const handleTypeChange = (arr) => {
    if (arr.length < 1) {
      setSelectedTypes(null);
    } else setSelectedTypes(arr);
  };

  if (!dateStr && !lang) return <PageLoader />;
  return (
    <section>
      <PageTitleModule data={{ title: "Program" }} />
      <StyledContainer>
        {chapters && performanceTags ? (
          <GridElement
            colStart={{ default: 2, s: 9 }}
            colEnd={{ default: 13, s: 24 }}
            rowStart={1}
          >
            <ProgramFilter
              setVisibility={setVisibility}
              handleDateChange={handleDateChange}
              handleCityChange={handleCityChange}
              handleChapterChange={handleChapterChange}
              handleTypeChange={handleTypeChange}
              data={items}
			  cities={cities}
              chapters={chapters}
              performanceTags={performanceTags}
              lang={lang}
            />
          </GridElement>
        ) : null}
      </StyledContainer>
      <section ref={programListRef}>
        <ProgramList
		  setCities={setCities}
          dateStr={dateStr}
          lang={lang}
          isBlurry={isBlurry}
		  handleItems={setItems}
          selectedVenues={selectedVenues}
          selectedChapters={selectedChapters}
          selectedTypes={selectedTypes}
          elementsToLoad={loadedElements}
        />
      </section>
    </section>
  );
};
export default ProgramPage;

const StyledContainer = styled(Container)`
  padding: 78px 0 48px;

  ${Media.greaterThan("s")`
        padding: 0 30px 173px;
    `}
`;

const StyledThumbnailList = styled(ThumbnailList)`
  opacity: ${(props) => (props.isBlurry ? 0.05 : 1)};
  ${(props) => props.theme.variables.transition}
  transition-property: opacity;
  transition-duration: 350ms;
`;
