import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import A from 'components/A';

import Media from 'css/Media';

export const tableQuery = pageType => `
    ... on ${pageType}_Modules_Builder_TableModule {
        fieldGroupName
            item {
                title
                input {
                    link
                    text
                    itemLink {
                        target
                        title
                        url
                    }
                }
          }
    }
`

const TableModule = props => {
    const { data } = props;
    return (
        <Wrapper>
            { data.item.map((tableItem, idx) => {
                return (
                    <Container key={idx}>
                        <GridElement
                            colStart={{default: 2, s: 5}}
                            colEnd={{default: 5, s: 8}}
                        >
                            <Title>{tableItem.title}</Title>
                        </GridElement>
                        {tableItem.input.map((input,i) => {
                            return input.link ?
                                <GridElement
                                    colStart={{default: 6, s: 9}}
                                    colEnd={{default: 13, s: 24}}
                                    rowStart={i + 1}
                                    key={i}
                                >
                                    <Text>→ <A href={input.itemLink.url} target={input.itemLink.target}>{input.itemLink.title}</A></Text>
                                </GridElement>
                            :
                            <GridElement
                                colStart={{default: 6, s: 9}}
                                colEnd={{default: 13, s: 24}}
                                rowStart={i + 1}
                                key={i}
                            >
                                <Text>{input.text}</Text>
                            </GridElement>
                        })}
                    </Container>
                )
            })}
        </Wrapper>
    )
}

export default TableModule;

const Wrapper = styled.section`
    p {
        padding-bottom: 8px;

        ${Media.greaterThan('s')`
            padding-bottom: 16px;
        `}
    }

    a {
        ${Media.greaterThan('s')`
            border-color: transparent;
        
            &:hover {
                border-bottom: 1px solid ${props => props.theme.colors.dark};
            }
        `}
    }
`

const Title = styled.p`
    ${props => props.theme.fonts.h8}
    line-height: 20px;
    font-weight: 500;

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h5}
        font-weight: 500;
    `}
`

const Text = styled.p`
    ${props => props.theme.fonts.h8}
    line-height: 20px;

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h5}
    `}
`