import React from "react";
import styled from "styled-components";

import ImageModule from "./ImageModule";
import PageTitleModule from "./PageTitleModule";
import PDFLinkListModule from "./PDFLinkListModule";
import VideoModule from "./VideoModule";
import QuoteModule from "./QuoteModule";
import LogoListModule from "./LogoListModule";
import TagListModule from "./TagListModule";
import DividerModule from "./DividerModule";
import ImageCarouselModule from "./ImageCarouselModule";
import RichTextModule from "./RichTextModule";
import TableModule from "./TableModule";
import FeaturedArticleModule from "./FeaturedArticleModule";
import ArticleListModule from "./ArticleListModule";
import EventListModule from "./EventListModule";
import QuickLinkModule from "./QuickLinkModule";

const moduleSwitch = (
  module,
  debug,
  pageType,
  key,
  reports,
  eventListIndex,
  articleListIndex
) => {
  if (process.env.NODE_ENV === "development" && debug) {
    console.log("passing through the builder: ", module);
  }
  switch (module.fieldGroupName) {
    case pageType + "_Modules_Builder_ImageModule":
      return <ImageModule data={module} key={key} />;
    case pageType + "_Modules_Builder_PageTitleModule":
      return <PageTitleModule data={module} key={key} />;
    case pageType + "_Modules_Builder_PdfLinkListModule":
      return <PDFLinkListModule reports={reports} key={key} />;

    case pageType + "_Modules_Builder_ArticleListModule":
      return (
        <ArticleListModule
          data={module}
          key={key}
          pageType={pageType}
          typeIndex={articleListIndex}
        />
      );

    case pageType + "_Modules_Builder_EventListModule":
      return (
        <EventListModule
          data={module}
          key={key}
          pageType={pageType}
          typeIndex={eventListIndex}
        />
      );

    case pageType + "_Modules_Builder_VideoModule":
      return <VideoModule data={module} key={key} />;

    case pageType + "_Modules_Builder_QuoteModule":
      return <QuoteModule data={module} key={key} />;

    case pageType + "_Modules_Builder_LogoListModule":
      return <LogoListModule data={module} key={key} />;

    case pageType + "_Modules_Builder_TagListModule":
      return <TagListModule data={module} key={key} />;

    case pageType + "_Modules_Builder_DividerModule":
      return <DividerModule data={module} key={key} />;

    case pageType + "_Modules_Builder_ImageCarouselModule":
      return <ImageCarouselModule data={module} key={key} />;

    case pageType + "_Modules_Builder_RichTextModule":
      return <RichTextModule data={module} key={key} />;

    case pageType + "_Modules_Builder_TableModule":
      return <TableModule data={module} key={key} />;

    case pageType + "_Modules_Builder_FeaturedArticleModule":
      return <FeaturedArticleModule data={module} key={key} />;
    case pageType + "_Modules_Builder_QuickLinkModule":
      return <QuickLinkModule data={module} key={key} />;

    default:
      console.log("Didn't hit any builder modules");
  }
};

const Builder = ({ modules, debug, reports, pageType, ...rest }) => {
  //We pass the entry index of the module if eventList and articleList, because we fetch their module data outside the moduleQuery
  //and because of this need to ensure we're presenting data from the correct one.
  let articleListIndex = -1;
  let eventListIndex = -1;
  if (!modules || !modules.length) return null;
  return (
    <Wrapper>
      {modules.map((module, key) => {
        if (
          module.fieldGroupName ===
          pageType + "_Modules_Builder_EventListModule"
        ) {
          eventListIndex = eventListIndex + 1;
        } else if (
          module.fieldGroupName ===
          pageType + "_Modules_Builder_ArticleListModule"
        ) {
          articleListIndex = articleListIndex + 1;
        }
        return moduleSwitch(
          module,
          debug,
          pageType,
          key,
          reports,
          eventListIndex,
          articleListIndex
        );
      })}
    </Wrapper>
  );
};

export default Builder;

const Wrapper = styled.section``;
