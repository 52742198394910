import React from 'react';

export default props => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22">
            <g id="Group_1" data-name="Group 1" transform="translate(-1070.308 -790.514)">
                <path id="Path_1" data-name="Path 1" d="M1070.53,801.016l9.8-10.175a.79.79,0,0,1,.594-.326.62.62,0,0,1,.464.192.639.639,0,0,1,.185.46.959.959,0,0,1-.037.278.457.457,0,0,1-.148.22l-5.475,5.673-4.139,4.1-.185-.479,3.9-.115,20.173-.007a.619.619,0,0,1,.464.192.709.709,0,0,1,0,.957.619.619,0,0,1-.464.192l-20.173.007-3.9-.115.185-.479,4.139,4.1,5.475,5.673a.645.645,0,0,1,.139.22.791.791,0,0,1,.046.278.638.638,0,0,1-.185.46.62.62,0,0,1-.464.192.692.692,0,0,1-.483-.211l-9.91-10.291a.67.67,0,0,1,0-1Z" transform="translate(0 0)"/>
            </g>
        </svg>
    );
}