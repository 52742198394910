import React, { useEffect } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { dispatch } from "../redux";
import { useSelector } from "react-redux";

import Container from "../components/Container";
import GridElement from "../components/GridElement";
import PageLoader from "components/partials/PageLoader";
import ErrorBoundary from "components/partials/ErrorBoundary";
import Seo from "components/partials/Seo";
import Builder from "components/modules/Builder";
import ErrorPage from "views/ErrorPage";

import { seoQuery } from "hooks/useSeo";
import { modulesQuery } from "const/modulesQuery";
import Media from "../css/Media";

const pageQuery = (slug, lang) => `
    chapter(id: "${slug}", idType: URI) {
        title
        ${seoQuery}
        ${modulesQuery("Chapter")}
        translation(language: ${lang === "no" ? "EN" : "NO"}) {
            uri
        }
      }
`;

const assembleRequestSlug = (params) => {
  const { grandparent, parent, slug } = params;
  //check if grandparent or parent is present and assemble the request slug based on it
  return `${grandparent ? grandparent + "/" : ""}${
    parent ? parent + "/" : ""
  }${slug}`;
};

const Page = (params) => {
  const slug = assembleRequestSlug(params);
  const lang = useSelector((state) => state.lang);
  const data = useRequest(pageQuery(slug, lang), slug);

  useEffect(() => {
    if (data) {
      let chapter;
      if (data.title.includes("nyMusikk ")) {
        chapter = data.title.slice(9);
      } else if (data.title.includes("nyMusikks ")) {
        chapter = data.title.slice(10);
      } else {
        chapter = data.title;
      }
      dispatch("chapter/update", chapter);
      dispatch("currentView/update", {
        view: "chapter",
        no: lang === "no" ? slug : data.translation.uri,
        en: lang === "no" ? data.translation?.uri : slug,
      });
    }
    return () => {
      dispatch("chapter/clear");
      dispatch("currentView/clear");
    };
  }, [data, lang, slug]);

  if (data === null) return <ErrorPage />;
  if (!data) return <PageLoader />;
  return (
    <>
      <Seo
        title={data.title}
        keywords={data.thumbnailData.buzzwords}
        description={data.thumbnailData.description}
        featuredImage={data.thumbnailData.featuredImage?.mediaItemUrl}
        imgAlt={data.thumbnailData.featuredImage?.altText}
        imgWidth={data.thumbnailData.featuredImage?.mediaDetails.width}
        imgHeight={data.thumbnailData.featuredImage?.mediaDetails.height}
        published={data.date}
        modified={data.modified}
      />
      <HeaderContainer>
        <GridElement
          colStart={{ default: 6, s: 9 }}
          colEnd={{ default: 12, s: 18 }}
        >
          <h1>{data.title}</h1>
        </GridElement>
      </HeaderContainer>
      <Wrapper>
        <ErrorBoundary for="Chapter Builder">
          <Builder modules={data.modules.builder} pageType="Chapter" />
        </ErrorBoundary>
      </Wrapper>
    </>
  );
};

export default Page;

const HeaderContainer = styled(Container)`
  position: absolute;
  padding: 78px 0 32px;
  visibility: hidden;

  h1 {
    margin-top: -10px;
  }
  p {
    ${(props) => props.theme.fonts.h7}
  }

  ${Media.greaterThan("s")`
        visibility: visible;
        padding: 0 30px 173px;

        h1 {
            margin-top: -26px;
        }
        p {
            ${(props) => props.theme.fonts.h4}
        }
    `}

  ${Media.greaterThan("l")`
        max-width: 1800px;
        margin-left: calc((100vw - 1800px) / 2);
    `}
`;

const Wrapper = styled.div`
  padding-top: calc(30px + 78px);

  ${Media.greaterThan("s")`
        padding-top: calc(80px + 173px);
    `}
`;
