import React from 'react';
import styled from 'styled-components';
import { dispatch } from '../../../redux';

import A from 'components/A';
import Image from 'components/Image';
import Overlay from 'components/partials/ImageOverlay';
import RatioWrapper from 'components/partials/RatioWrapper';

import Media from 'css/Media';

const Hit = ({ hit }) => {

    if (!hit.url || !hit.title) {
        return null
    }

    const toggleSearch = () => {
        dispatch('openSearch/toggle');
    }

  return (
    <StyledDiv onClick={() => toggleSearch()}>
            <StyledA href={hit.url}>
                { hit.featured_image ? 
                    <Overlay>
                        <StyledRatioWrapper ratio="4:3">
                            <StyledImage 
                                src={hit.featured_image} 
                                alt={hit.title}
                            />
                        </StyledRatioWrapper>
                    </Overlay>
                : null}
            </StyledA>
            <StyledA href={hit.url}><span>{hit.title}</span></StyledA>
    </StyledDiv>
  )
}

export default Hit;

const StyledDiv = styled.div`
    margin-bottom: 32px;
    text-align: left;
    
    span {
        ${props => props.theme.fonts.h5}
    }

    ${Media.greaterThan('s')`
        margin-bottom: 64px;
    `}
`

const StyledA = styled(A)`
    width: 100%;
`

const StyledRatioWrapper = styled(RatioWrapper)`
    margin-bottom: 16px;
`

const StyledImage = styled(Image)`
    max-width: none;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
`