import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import Image from 'components/Image';
import Divider from 'components/Divider';
import FadeIn from 'components/partials/FadeIn';

import Media from 'css/Media';

export const logoListQuery = pageType => `
    ... on ${pageType}_Modules_Builder_LogoListModule {
        fieldGroupName
        title
        logoList {
            logo {
                mediaItemUrl
            }
        }
    }
`

export default function LogoListModule(props) {
    const { data } = props;
    if(!data) return null;
    
    return (
        <Wrapper>
            <Container>
                <GridElement
                    colStart={{default: 2, s: 5}}
                    colEnd={{default: 13, s: 24}}
                >
                    <TopDivider />
                </GridElement>
            </Container>
            {data.title ? 
                <Container>
                    <GridElement
                        colStart={{default: 2, s: 5}}
                        colEnd={{default: 13, s: 24}}
                    >
                        <h2>{data.title}</h2>
                    </GridElement>
                </Container>
            : null}
            <StyledContainer>
                { data.logoList.map((item,idx) => {
                    return (
                        <StyledDiv key={idx}>
                            <FadeIn><StyledImage src={item.logo.mediaItemUrl} /></FadeIn>
                        </StyledDiv>
                    )
                })}
            </StyledContainer>
            <Container>
                <GridElement
                    colStart={{default: 2, s: 5}}
                    colEnd={{default: 13, s: 24}}
                >
                    <Divider />
                </GridElement>
            </Container>
        </Wrapper>
    )
}


const Wrapper = styled.section`
    h2 {
        ${props => props.theme.fonts.h2}
        margin-bottom: 60px;
    }
    margin-bottom: 48px;

    ${Media.greaterThan('s')`
        margin-bottom: 124px;
    `}
`

const TopDivider = styled(Divider)`
    margin-bottom: 38px;
    ${Media.greaterThan('s')`
        margin-bottom: 74px;
    `}
`

const StyledContainer = styled(Container)`
    margin-bottom: 65px;

    ${Media.greaterThan('s')`
        margin-bottom: 82px;
    `}
`

const StyledImage = styled(Image)`
    text-align: left;
    margin-bottom: 16px;

    ${Media.greaterThan('s')`
        margin-bottom: 32px;
    `}
`

const StyledDiv = styled.div`
    align-self: center;
    &:nth-of-type(odd) {
        -ms-grid-column: 2;
        -ms-grid-column-span: 5;
        grid-column-start: 2;
        grid-column-end: 7;
    }
    &:nth-of-type(even) {
        -ms-grid-column: 8;
        -ms-grid-column-span: 5;
        grid-column-start: 8;
        grid-column-end: 13;
    }

    ${Media.greaterThan('s')`
        &:nth-of-type(5n+1) {
            -ms-grid-column: 5;
            -ms-grid-column-span: 3;
            grid-column-start: 5;
            grid-column-end: 8;
            
        }
        &:nth-of-type(5n+2) {
            -ms-grid-column: 9;
            -ms-grid-column-span: 3;
            grid-column-start: 9;
            grid-column-end: 12;
            
        }

        &:nth-of-type(5n+3) {
            -ms-grid-column: 13;
            -ms-grid-column-span: 3;
            grid-column-start: 13;
            grid-column-end: 16;
            
        }
        &:nth-of-type(5n+4) {
            -ms-grid-column: 17;
            -ms-grid-column-span: 3;
            grid-column-start: 17;
            grid-column-end: 20;
            
        }
        &:nth-of-type(5n+5) {
            -ms-grid-column: 21;
            -ms-grid-column-span: 3;
            grid-column-start: 21;
            grid-column-end: 24;
        }
    `}
`