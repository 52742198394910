import React from 'react';
import styled from 'styled-components';
import { dispatch } from '../../redux';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import A from 'components/A';
import ArrowSmall from 'icons/ArrowSmall';

import Media from 'css/Media';

export const quickLinkQuery = pageType => `
    ... on ${pageType}_Modules_Builder_QuickLinkModule {
        fieldGroupName
        link {
            url
            target
        }
        linkText
        linkType
        searchValue
    }
`

const QuickLinkModule = props => {

    const toggleSearch = () => {
        dispatch("openSearch/toggle");
        dispatch("openMenu/clear");
        //pass on the tag value for search input
    }
    const { data } = props;
    
    return (
        <Container>
            <StyledGridElement
                colStart={{default: 2, s: 5}}
                colEnd={{default: 13, s: 13}}
            >
                <StyledArrowSmall />
                { data.linkType === 'triggersSearch' ? 
                    <StyledDiv onClick={toggleSearch}>{data.linkText}</StyledDiv>
                :
                    <StyledA href={data.link.url} target={data.link.target}>{data.linkText}</StyledA>
                }
            </StyledGridElement>
        </Container>
    )
}

export default QuickLinkModule;

const StyledGridElement = styled(GridElement)`
    display: flex;
    align-items: center;
    margin: 0 0 64px;

    ${Media.greaterThan('s')`
        margin: 54px 0 160px;
    `}
`

const StyledDiv = styled.div`
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    white-space: nowrap;
    ${props => props.theme.fonts.h5}

    &::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 100%;
        background-color: ${props => props.theme.colors.dark};
        bottom: 0;
        left: 0;
        ${props => props.theme.variables.transition}
        transition-duration: 250ms;
    }

    &:active {
        &::after {
            background-color: transparent;
        }
    }
    

    ${Media.greaterThan('s')`
        &:hover {
            &::after {
                background-color: transparent;
            }
        }
    `}
`

const StyledA = styled(A)`
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    white-space: nowrap;
    ${props => props.theme.fonts.h5}

    &::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 100%;
        background-color: transparent;
        bottom: 0;
        left: 0;
        ${props => props.theme.variables.transition}
        transition-duration: 250ms;
    }
    

    &:active {
        &::after {
            background-color: ${props => props.theme.colors.dark};
        }
    }
    

    ${Media.greaterThan('s')`
        &::after {
            background-color: transparent;
        }
        &:hover {
            &::after {
                background-color: ${props => props.theme.colors.dark};
            }
        }
    `}
`

const StyledArrowSmall = styled(ArrowSmall)`
    transform: rotate(180deg);
    margin-right: 6px;
`