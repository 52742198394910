import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';

import Media from 'css/Media';

export const pageTitleQuery = pageType => `
    ... on ${pageType}_Modules_Builder_PageTitleModule {
        fieldGroupName
        title
    }
`

export default function PageTitleModule(props) {
    const { data } = props;
    return (
        <StyledContainer grid>
            <GridElement
                colStart={{default: 2, s: 9}}
                colEnd={{default: 13, s: 18}}
            >
            <h1>{data.title}</h1>
            </GridElement>
        </StyledContainer>
    )
}

const StyledContainer = styled(Container)`
    padding: 78px 0 32px;

    ${Media.greaterThan('s')`
        padding: 0 30px 173px;

        h1 {
            margin-top: -14px;
        }
    `}
`