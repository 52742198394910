import React, { useEffect } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { dispatch } from "../redux";
import { useSelector } from "react-redux";

import ErrorBoundary from "components/partials/ErrorBoundary";
import PageLoader from "components/partials/PageLoader";
import Seo from "components/partials/Seo";
import Builder from "components/modules/Builder";

import { seoQuery } from "hooks/useSeo";
import { modulesQuery } from "const/modulesQuery";
import Media from "../css/Media";

const pageQuery = (slug, lang) => `
    page(id: "${slug}", idType: URI) {
        title
        content
        ${seoQuery}
        ${modulesQuery("Page")}
        translation(language: ${lang === "no" ? "EN" : "NO"}) {
            uri
        }
    }
`;

function Home() {
  const lang = useSelector((state) => state.lang);
  const data = useRequest(
    pageQuery(lang === "no" ? "home" : "home-2", lang),
    "home"
  );
  
  useEffect(() => {
    if (data) {
      dispatch("currentView/update", {
        view: "home",
        no: "/home",
        en: "/en/home-2",
      });
    }
    return () => {
      dispatch("currentView/clear");
    };
  }, [data]);

  if (!data) return <PageLoader />;
  return (
    <Wrapper>
      <Seo
        title={data.title}
        keywords={data.thumbnailData.buzzwords}
        description={data.thumbnailData.description}
        featuredImage={data.thumbnailData.featuredImage?.mediaItemUrl}
        imgAlt={data.thumbnailData.featuredImage?.altText}
        imgWidth={data.thumbnailData.featuredImage?.mediaDetails.width}
        imgHeight={data.thumbnailData.featuredImage?.mediaDetails.height}
        published={data.date}
        modified={data.modified}
      />
      <ErrorBoundary for="Home Builder">
        <Builder modules={data.modules.builder} pageType="Page" />
      </ErrorBoundary>
    </Wrapper>
  );
}

export default Home;

const Wrapper = styled.div`
  padding-top: calc(30px + 78px);

  ${Media.greaterThan("s")`
        padding-top: calc(80px + 173px);
    `}
`;
