import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './nymusikk_ikon_hover.json'
 
export default class LottieControl extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }
 
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }

    };

    return (
        <Lottie options={defaultOptions}
            width={59}
            height={28}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
        />
    )
  }
}
 
