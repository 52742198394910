import React from 'react';
import styled from 'styled-components';

import Media from 'css/Media';

const Html = props => {
    return(
        <Wrapper
            className={props.className} 
            dangerouslySetInnerHTML={{ __html: props.children }} 
        />
    )
}

export default Html;

const Wrapper = styled.div`
    p, h2, li {
        font-size: 15px;
        line-height: 25px;
            
        ${Media.greaterThan('s')`
            ${props => props.theme.fonts.h6}
        `}
    }

    h2 {
        font-weight: 500;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: 500;
    }

    a {
        color: ${props => props.theme.colors.dark};
        text-decoration: none;
        border-bottom: 1px solid ${props => props.theme.colors.dark};
        ${props => props.theme.variables.transition}
        transition-duration: 250ms;

        &:hover {
            border-color: transparent;
        }

        &:active,
        &:visited,
        &:focus {
            color: ${props => props.theme.colors.dark};
        }
    }

    ul {
        padding-left: 0;
        padding-inline-start: 17px;
        margin-bottom: 20px;

        li {
            padding-left: 0px;
            list-style: disc;
        }
    }

    ol {
        padding-inline-start: 14px;
        margin-bottom: 20px;
        
        li {
            padding-left: 9px;
            list-style: decimal;
        }
    }
`