import React from 'react';
import { useSelector } from 'react-redux';

import ErrorBoundary from 'components/partials/ErrorBoundary';
import PageTitleModule from 'components/modules/PageTitleModule';
import QuickLinkModule from 'components/modules/QuickLinkModule';

const getContent = lang => ({
    linkType: 'link',
    link: {
        url: lang === 'no' ? '/home' : '/en/home-2',
        target: ""
    },
    linkText: lang === 'no' ? "Trykk her for å gå tilbake til forsiden" : "Click here to go back to the front page"
})

function ErrorPage() {
    const lang = useSelector(state => state.lang);
    return (
        <section>
            <ErrorBoundary for="Error page">
                <PageTitleModule 
                    data={lang === 'no' ? {title: "404 - siden finnes ikke"} : {title: "404 - this site don't exist"}}
                />
                <QuickLinkModule 
                    data={getContent(lang)}

                />
            </ErrorBoundary>
        </section>
    )
}

export default ErrorPage;
