import { createStore/* , applyMiddleware */ } from "redux";
/* import { createLogger } from "redux-logger"; */

import reducers from "./reducers";

/* const logger = createLogger({
    level: "info",
}); */

const configureStore = () => {
    const store = createStore(reducers/* , applyMiddleware(logger) */);

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./reducers", () => {
                store.replaceReducer(reducers);
            });
        }
    }

    return store;
};

const configuredStore = configureStore();

export default configuredStore;
