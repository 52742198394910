import React, { useEffect } from "react";
import useRequest from "kb-gql";
import { useSelector } from "react-redux";
import { dispatch } from "../redux";

import ErrorBoundary from "components/partials/ErrorBoundary";
import PageLoader from "components/partials/PageLoader";
import Seo from "components/partials/Seo";
import Builder from "components/modules/Builder";

import ErrorPage from "views/ErrorPage";

import { seoQuery } from "hooks/useSeo";
import { modulesQuery } from "const/modulesQuery";

const annualReportsQuery = (lang) => `
    annualReports(last: 99, where: {language: ${lang}}) {
        nodes {
            annual_reports {
                fieldGroupName
                title
                link {
                    mediaItemUrl
                }
                image {
                    mediaItemUrl
                }
            }
        }
    }
`;

const newsArticlePageQuery = (slug, lang) => `
    newsArticle(id: "${slug}", idType: URI) {
        title
        ${modulesQuery("NewsArticle")}
        ${seoQuery}
        translation(language: ${lang === "no" ? "EN" : "NO"}) {
            uri
        }
    }
`;

const NewsArticlePage = (params) => {
  const slug = params.slug;
  const lang = useSelector((state) => state.lang);
  const data = useRequest(newsArticlePageQuery(slug, lang), slug);
  const reports = useRequest(annualReportsQuery(lang.toUpperCase()), "reports");

  useEffect(() => {
    if (data)
      dispatch("currentView/update", {
        view: "article",
        no: lang === "no" ? slug : data.translation?.uri,
        en: lang === "no" ? data.translation?.uri : slug,
      });
    return () => {
      dispatch("currentView/clear");
    };
  }, [data, lang, slug]);

  if (data === null) return <ErrorPage />;
  if (!data) return <PageLoader />;

  return (
    <section>
      <Seo
        title={data.title}
        keywords={data.thumbnailData.buzzwords}
        description={data.thumbnailData.description}
        featuredImage={data.thumbnailData.featuredImage?.mediaItemUrl}
        imgAlt={data.thumbnailData.featuredImage?.altText}
        imgWidth={data.thumbnailData.featuredImage?.mediaDetails.width}
        imgHeight={data.thumbnailData.featuredImage?.mediaDetails.height}
        published={data.date}
        modified={data.modified}
      />
      <ErrorBoundary for="Builder">
        <Builder
          modules={data.modules?.builder}
          reports={reports.nodes}
          pageType="NewsArticle"
        />
      </ErrorBoundary>
    </section>
  );
};

export default NewsArticlePage;
