import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Header from 'components/partials/Header/Header';
import Footer from 'components/partials/Footer/index';
import ErrorBoundary from 'components/partials/ErrorBoundary';
import Seo from 'components/partials/Seo';

const MasterLayout = ({children}) => {
    const searchOpen = useSelector(state => state.searchState.open);
    const menuOpen = useSelector(state => state.menuState.open);
    
    return (
        <Wrapper>
            <Seo />
            <ErrorBoundary for="Header">
                <Header />
            </ErrorBoundary>
                <OpacityWrapper isVisible={!searchOpen && !menuOpen}>
                    {children}
                </OpacityWrapper>
            <ErrorBoundary for="Footer">
                <Footer isVisible={!searchOpen && !menuOpen} />
            </ErrorBoundary>
        </Wrapper>
    )
}

export default MasterLayout;

const Wrapper = styled.div`
    padding: 40px 0;
    
`
const OpacityWrapper = styled.div`
    opacity: ${props => props.isVisible ? 1 : 0};
    ${props => props.theme.variables.transition}
    transition-duration: 480ms;
`