import React from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import Logo from "icons/Logo";
import CloseIcon from "icons/CloseIcon";
import Hamburger from "../Hamburger";
import Menu from "../Menu";
import Search from "../Search/Search";
import Container from "../../Container";
import GridElement from "../../GridElement";
import A from "../../A";

function MobileHeader(props) {
  let isLight = useSelector((state) => state.headerColor.light);
  let isFeatured = useSelector((state) => state.isFeatured);

  if (!props) return null;

  const {
    menuIsOpen,
    searchIsOpen,
    chapter,
    lang,
    menu,
    toggleMenu,
    toggleSearch,
  } = props;
  return (
    <React.Fragment>
      <MobileContainer isLight={isLight}>
        <GridElement colStart={2} colEnd={4}>
          <A href={lang === "no" ? "/home" : "/en/home-2"}>
            <StyledLogo
              isLight={
                isLight
                  ? menuIsOpen
                    ? false
                    : searchIsOpen
                    ? false
                    : true
                  : false
              }
            />
          </A>
        </GridElement>
        {!menuIsOpen && !searchIsOpen ? (
          <NameGridElement
            colStart={6}
            colEnd={10}
            justify="center"
            isFeatured={isFeatured}
            isLight={isLight}
          >
            <p>nyMusikk {chapter}</p>
          </NameGridElement>
        ) : null}
        <GridElement colStart={12} colEnd={13} justify="end">
          <Hamburger
            clickHandler={toggleMenu}
            open={menuIsOpen}
            isVisible={!searchIsOpen}
          />
        </GridElement>
        <CloseSearchGridElement
          colStart={12}
          colEnd={13}
          justify="end"
          open={searchIsOpen}
        >
          <CloseSearch onClick={toggleSearch} />
        </CloseSearchGridElement>
      </MobileContainer>

      <Menu open={menuIsOpen} menu={menu} />
      <Search open={searchIsOpen} />
    </React.Fragment>
  );
}

export default MobileHeader;

const MobileContainer = styled(Container)`
  position: absolute;
  top: 40px;
  z-index: 1002;
`;

const StyledLogo = styled(Logo)`
  cursor: pointer;
  position: relative;
  //margin-left: 10px;

  ${(props) =>
    props.isLight &&
    css`
      fill: ${(props) => props.theme.colors.negative};
      path {
        fill: ${(props) => props.theme.colors.negative};
      }
    `}
`;

const NameGridElement = styled(GridElement)`
  ${(props) =>
    props.isFeatured &&
    css`
      position: absolute;
      top: 250px;
      left: 32px;
      transform: rotate(-90deg) translateY(-50%);
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1 / 2;

      p {
        white-space: nowrap;
        width: 28px;
      }
    `}

  p {
    ${(props) => props.theme.fonts.h7};
    ${(props) =>
      props.isLight &&
      css`
        color: ${(props) => props.theme.colors.negative};
      `}
  }
`;

const CloseSearchGridElement = styled(GridElement)`
  z-index: -1;
  opacity: 0;
  ${(props) => props.theme.variables.transition}
  transition-property: opacity;
  transition-delay: 0ms;

  ${(props) =>
    props.open &&
    css`
      z-index: 1;
      opacity: 1;
      transition-delay: 250ms;
    `};
`;

const CloseSearch = styled(CloseIcon)`
  position: fixed;
  top: 40px;
  right: 30px;
  width: 25.25px;
  height: 25.25px;
  cursor: pointer;

  line {
    stroke-width: 1px;
  }
`;
