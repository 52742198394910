import React, { useEffect } from 'react';
import styled from 'styled-components';
import { dispatch } from "../../redux";
import { useSelector } from 'react-redux';

import GridElement from 'components/GridElement';
import Image from 'components/Image';
import A from 'components/A';
import FadeIn from 'components/partials/FadeIn';
import Overlay from 'components/partials/ImageOverlay';
import ArrowSmall from 'icons/ArrowSmall';

import useIsMobile from 'hooks/useIsMobile';
import Media from 'css/Media';

export const featuredArticleQuery = pageType =>`
    ... on ${pageType}_Modules_Builder_FeaturedArticleModule {
        fieldGroupName
        toggleHeaderColor
        article {
            ... on NewsArticle {
                title
                uri
                thumbnailData {
                    description
                    featuredImage {
                        mediaItemUrl
                    }
                }
            }
        }
    }
`

const FeaturedArticle = props => {
    const lang = useSelector(state => state.lang);
    const isMobile = useIsMobile();
    useEffect(() => {
        if(!props.data.toggleHeaderColor) {
            dispatch("headerColor/toggle")
        }
        dispatch("isFeatured/setFeatured")

        return(() => {
            dispatch("isFeatured/clear")
            dispatch("headerColor/clear")
        })
    }, [props.data.toggleHeaderColor])

    const article = props.data.article;
    let href = article.uri;

    if(!article) return null;
    return (
        <Wrapper>
            <Grid>
                <GridElement
                    colStart={{default: 1, s: 5}}
                    colEnd={{default: 14, s: 16}}
                    rowStart={1}
                >
                    { isMobile ?
                        <FadeIn shouldScale>
                            <StyledOverlay><Image src={article.thumbnailData.featuredImage.mediaItemUrl} /></StyledOverlay>
                        </FadeIn>
                    :
                        <FadeIn shouldScale><A href={href}>
                            <StyledOverlay><Image src={article.thumbnailData.featuredImage.mediaItemUrl} /></StyledOverlay>
                        </A></FadeIn>
                    }
                </GridElement>
                <GridElement
                    colStart={{default: 4, s: 17}}
                    colEnd={{default: 13, s: 24}}
                    justify={{default: 'start', s: 'center'}}
                    rowStart={{default: 2, s: 1}}
                >   
                    <StyledDiv>
                        <A href={href}><h2>{article.title}</h2></A>
                        <p>{article.thumbnailData.description}</p>
                        <p><StyledArrowSmall /><A href={href}>{lang === 'no' ? 'Les mer' : 'Read more'}</A></p>
                    </StyledDiv>
                </GridElement>
            </Grid>
        </Wrapper>
    )
}

export default FeaturedArticle;

const Wrapper = styled.div`
    margin-top: -148px;
    margin-bottom: 50px;

    p {
        ${props => props.theme.fonts.p}
        padding-bottom: 16px;

        a {
            text-decoration: none;
            ${props => props.theme.variables.transition}
            transition-duration: 250ms;

            ${Media.greaterThan('s')`
                border-color: transparent;
                
                &:hover {
                    border-bottom: 1px solid ${props => props.theme.colors.dark};
                }
            `}
        }
            
        ${Media.greaterThan('s')`
            margin-top: 0;
        `}
    }

    h2 {
        ${props => props.theme.fonts.h2}
        padding-bottom: 16px;

        ${Media.greaterThan('s')`
            padding-bottom: 32px;
            font-size: 32px;
            line-height: 40px;
        `}

        ${Media.greaterThan('l')`
            padding-bottom: 32px;
            font-size: 42px;
            line-height: 50px;
        `}
    }

    img {
        max-width: none;
        width: 100%;
        height: 56.65vh;
        object-fit: cover;

        ${Media.greaterThan('s')`
            object-fit: fill;
            height: auto;
        `}
    }

    ${Media.greaterThan('s')`
        margin-top: 0;
        margin-bottom: 180px;
    `}
`

const Grid = styled.div`
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: .5fr 1fr .5fr 1fr .5fr 1fr .5fr 1fr .5fr 1fr .5fr 1fr .5fr;
    grid-template-columns: repeat(6, .5fr 1fr) .5fr;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;

    ${Media.greaterThan('s')`
        -ms-grid-columns: 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr .1702fr 1fr 30px;
        grid-template-columns: repeat(11, 1fr .1702fr) 1fr 30px;
        padding: 0 0 0 30px;
    `}
`

const StyledOverlay = styled(Overlay)`
    margin-bottom: 32px;

    ${Media.greaterThan('s')`
        margin-bottom: 0;
    `}
`

const StyledDiv = styled.div`
    ${Media.greaterThan('s')`
        padding: 0 45px;
    `}

    ${Media.greaterThan('l')`
        padding: 0 90px;
    `}
`

const StyledArrowSmall = styled(ArrowSmall)`
    transform: rotate(180deg);
    margin-right: 4px;
`