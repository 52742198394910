import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useIsMobile from 'hooks/useIsMobile';
import { dispatch } from "../../redux";

import Container from '../Container';
import GridElement from '../GridElement';
import A from '../A';
import LanguageSwitcher from './LanguageSwitcher';

import Media from 'css/Media';

const Menu = props => {
    let lang = useSelector(state => state.lang);
    let location = window.location.pathname;
    let isMobile = useIsMobile();

    useEffect(() => {
        if (props.open) {
            document.getElementsByTagName('body')[0].style.overflowY = 'hidden';

        }
        return(() => {
            document.getElementsByTagName('body')[0].style.overflowY = 'visible';
        })
        
    }, [props.open]);

    useEffect(() => {
        if (location) {
            closeMenu();
        }
        
    }, [location]);

    useEffect(() => {
        document.addEventListener("keydown", function(event) {
            if(event.keyCode === 27){
               //Esc key was pressed
               closeMenu();
           }
        });
        return(() => {
            document.removeEventListener("keydown", function(event) {
            })
        })
    },[])

    const toggleSearch = () => {
        dispatch("openSearch/toggle");
        dispatch("openMenu/clear");
    }
    
    const closeMenu = () => {
        dispatch("openMenu/clear");
    }

    return (
        <Wrapper open={props.open}>
            <StyledContainer open={props.open}>
                { props.menu.map((item, idx) => {
                    return (
                        <ListItemGridElement
                            colStart={{default: 2, s: 9}}
                            colEnd={{default: 13, s: 20}}
                            rowStart={{default: "auto"}}
                            key={idx}
                            last={idx === props.menu.length - 1}
                            onClick={closeMenu}
                        >
                            <A href={item.url}>{item.label}</A>
                        </ListItemGridElement>   
                    )})
                }
                { isMobile ? 
                    <GridElement
                        colStart={2}
                        colEnd={13}
                        rowStart={{default: "auto"}}
                    >
                        <LanguageSwitcher />
                    </GridElement>
                : null}
                { isMobile ? 
                    <GridElement
                        colStart={2}
                        colEnd={13}
                        rowStart={{default: "auto"}}
                    >
                        <Search onClick={toggleSearch}>{lang === 'no' ? 'Søk' : 'Search'}</Search>
                    </GridElement>
                : null}
            </StyledContainer>
        </Wrapper>
    )
}

export default Menu;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    z-index: ${props => props.open ? 10 : -10};
    opacity: ${props => props.open ? 1 : 0};
    ${props => props.theme.variables.transition}
    transition-duration: 260ms;
    transition-property: opacity;
    overflow-y: scroll;
    
    ${Media.greaterThan('s')`
        margin-top: -14px;
    `}

`

const StyledContainer = styled(Container)`
    padding-top: 116px;
    opacity: ${props => props.open ? 1 : 0};
    ${props => props.theme.variables.transition}
    transition-duration: 260ms;
    transition-property: opacity;
    transition-delay: 265ms;

    ${Media.greaterThan('s')`
        padding-top: 40px;
    `}
`

const ListItemGridElement = styled(GridElement)`
    padding-bottom: ${props => props.last ? 24 : 8}px;
    position: relative;     
    z-index: 1002;
    a {
        ${props => props.theme.fonts.h1}
    }

    ${Media.greaterThan('s')`
        padding-bottom: 24px;
    `}
`

const Search = styled.p`
    padding-top: 16px;
    cursor: pointer;
    ${props => props.theme.fonts.h7};
`