import React from "react";
import { A as Link } from "hookrouter";
import styled from "styled-components";

const A = ({ href, target, ...props }) => {
  let url = href;

  if (href && href.length > 1) {
    if (href.substr(-1) === "/") url = url.slice(0, -1);
  }

  if (!url) url = "";

  if (target === "_blank") {
    return (
      <a href={url} target={target} {...props}>
        {props.children}
      </a>
    );
  }

  return (
    <StyledLink href={url} target={target} {...props} onClick={props.onClick}>
      {props.children}
    </StyledLink>
  );
};

export default A;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
