import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";

import ChevronDown from "icons/ChevronDown";
import CloseIcon from "icons/close.svg";

import useOnClickOutside from "hooks/useOnClickOutside";

import Media from "css/Media";

const SelectItem = (props) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [bool, setBool] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  const handleClick = (val) => {
    let arr = list;

    if (list.includes(val)) {
      arr = arr.filter((item) => item !== val);
    } else {
      arr = [...arr, val];
    }
    setList(arr);
    setBool(!bool);
    props.handleChange(arr);
  };

  const handleOpen = () => {
    setOpen(true);
    //hacky way to make sure date input is closed, if that is where we clicked from
    window.setTimeout(() => props.setVisibility(true), 50);
  };
  const handleClose = () => {
    if (open) {
      setOpen(false);
      props.setVisibility(false);
    } else return null;
  };

  return (
    <Wrapper className={props.className} ref={ref}>
      <InputTitle onClick={open ? () => handleClose() : () => handleOpen()}>
        <Arrow open={open} reverse={props.reverse} />
        <Title reverse={props.reverse}>{props.name}</Title>
      </InputTitle>
      {open ? (
        <Options>
          {props.arr.map((item, i) => {
            let hackToForceReRender = bool;
            return (
              <Option
                onClick={() => handleClick(item)}
                isSelected={list.includes(item)}
                key={props.name + "-" + i}
                hasLength={list.length > 0}
              >
                {item}
              </Option>
            );
          })}
        </Options>
      ) : null}
    </Wrapper>
  );
};

export default SelectItem;

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  height: 28px;

  svg {
    overflow: auto !important;
    &:not(:root) {
      overflow: auto !important;
    }
  }
`;

const Arrow = styled(ChevronDown)`
  order: 1;
  align-self: center;
  ${(props) => props.theme.variables.transition}
  transition-duration: 350ms;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  margin-right: 6px;
  background: transparent;

  path {
    stroke: ${(props) => props.theme.colors.text};
  }

  ${(props) =>
    props.reverse &&
    css`
      margin-left: 6px;
      order: 2;
    `}
`;

const InputTitle = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const Title = styled.p`
  order: 2;

  ${(props) =>
    props.reverse &&
    css`
      order: 1;
    `}

  ${(props) => props.theme.fonts.h7}
    
    ${Media.greaterThan("s")`
        ${(props) => props.theme.fonts.h4}
    `}
`;

const Options = styled.div`
  position: absolute;
  top: 56px;
  left: 20px;
`;

const Option = styled(Title)`
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.text
      : !props.hasLength
      ? props.theme.colors.text
      : props.theme.colors.alt};

  ${(props) =>
    props.isSelected &&
    css`
      ::before {
        position: absolute;
        content: url(${CloseIcon});
        top: 3px;
        left: -23px;
      }
    `}
`;
