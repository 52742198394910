import React from 'react';
import styled from 'styled-components';

import Spinner from 'icons/SpinnerBlack';
import Container from 'components/Container';

const PageLoader = props => {
    return(
        <StyledContainer>
            <StyledSpinner />
        </StyledContainer>
    )
}

export default PageLoader;

const StyledContainer = styled(Container)`
    position: relative;
    height: 80vh;
`

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`