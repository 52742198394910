import React from 'react';
import styled from 'styled-components';

import Media from 'css/Media';

export default function Overlay(props) {
    return (
        <StyledDiv className={props.className}>
            {props.children}
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    position: relative;
    height: auto;

    ${Media.greaterThan('s')`
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-color: transparent;
            ${props => props.theme.variables.transition}
            transition-duration: 300ms;
        }

        &:hover {
            &::after {
                background-color: rgba(0,0,0,.07);
            }
        } 
    `}
`