import React, { useState } from 'react';
import styled from 'styled-components';

import A from 'components/A';

import PlusIcon from 'icons/PlusIcon';
import MinusIcon from 'icons/MinusIcon';

import Media from 'css/Media';

export default function AccordionItem({
    title,
    content
}) {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <ItemWrapper onClick={handleClick}>
            <H5>{title}</H5>
            <Panel open={open}>
                { content.map((i,ix) => {
                    if(i.type === 'text') {
                        return (
                            <p key={ix}>{i.text}</p>
                        )
                    } else return (
                        <p key={ix}>
                            <A 
                                href={i.link.url} 
                                target={i.link.target}
                            >
                                {i.link.title}
                            </A>
                        </p>
                    )})
                }
            </Panel>
            <IconWrapper open={open}>{open ? <MinusIcon /> : <PlusIcon />}</IconWrapper>
        </ItemWrapper>
    )
};


const ItemWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #000;
    padding: 20px 0;

    ${Media.greaterThan('s')`
        padding: 50px 0;
    `}
`

const H5 = styled.h5`
    ${props => props.theme.fonts.h7}
    cursor: pointer;
    margin-right: 32px;

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h5}
    `}
`

const IconWrapper = styled.div`
    margin: 0 0 auto auto;
    margin-top: ${p => p.open ? -5 : 0}px;
`


const Panel = styled.div`
    display: ${p => p.open ? "block" : "none"};
    user-select: none;
    
    ${Media.lessThan('s')`
        p, a {
            ${props => props.theme.fonts.h8}
            line-height: 20px;
            margin-bottom: 10px;
        }
    `}
`
