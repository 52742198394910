import Media from './Media';

// Style guide
const theme = {
    colors: {
        negative: '#FFFFFF',
        dark: '#000000',
        alt: '#707070',
        disabled: '#F2F2F2',
        lines: '#E0E0E0',
        text: '#1F1F1F',
        bg: '#D7F2DC',
        button: '#F2F2F2',
        buttonHover: '#F8F8F8',
        danger: '#FC514A',
        tooltip: '#F7FBB3'
    },
    variables: {
        transition: () => `
            transition-property: all;
            transition-duration: 750ms;
            transition-timing-function: cubic-bezier(.64, .05, .5, .97);
        `
    },
    fonts: {
        h1: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 32px;
            font-weight: 500;
            line-height: 40px;
            
            ${Media.greaterThan('s')`
                font-size: 66px;
                line-height: 80px;
            `.join('')}
        `,
        h2: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 32px;
            font-weight: 500;
            line-height: 40px;
            
            ${Media.greaterThan('s')`
                font-size: 42px;
                line-height: 50px;
            `.join('')}
        `,
        h3: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
            
            ${Media.greaterThan('s')`
                font-size: 28px;
                line-height: 38px;
            `.join('')}
        `,
        h4: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 22px;
            line-height: 28px;
            
            ${Media.greaterThan('s')`
                font-size: 22px;
                line-height: 28px;
            `.join('')}
        `,
        h5: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 22px;
            line-height: 28px;
            font-weight: normal;
            
            ${Media.greaterThan('s')`
                font-size: 22px;
                line-height: 28px;
            `.join('')}
        `,
        h6: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 18px;
            line-height: 32px;
            
            ${Media.greaterThan('s')`
                font-size: 18px;
                line-height: 32px;
            `.join('')}
        `,
        h7: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 16px;
            line-height: 22px;
            
            ${Media.greaterThan('s')`
                font-size: 16px;
                line-height: 22px;
            `.join('')}
        `,
        h8: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 14px;
            line-height: 18px;
            
            ${Media.greaterThan('s')`
                font-size: 14px;
                line-height: 18px;
            `.join('')}
        `,
        h9: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 12px;
            line-height: 14px;
            
            ${Media.greaterThan('s')`
                font-size: 12px;
                line-height: 14px;
            `.join('')}
        `,
        p: () => `
            font-family: 'RightGrotesk', sans-serif;
            font-size: 15px;
            line-height: 25px;
            
            ${Media.greaterThan('s')`
                font-size: 18px;
                line-height: 32px;
            `.join('')}
        `,
    }
}

export default theme;