import React from 'react';
import styled from 'styled-components';

import SearchIcon from 'icons/SearchIcon';
import CloseIcon from 'icons/CloseIcon';

const SearchButton = props => {
    const { open, lang } = props;
    
    return (
        <Wrapper onClick={props.onClick}>
            <div>
                <FlexItem transform={open ? 1 : 0}>
                    <StyledP>{lang === 'no' ? 'Søk' : 'Search'}</StyledP>
                    <StyledSearch />
                </FlexItem>
                <FlexItem transform={open ? 1 : 0}>
                    <StyledP>{lang === 'no' ? 'Lukk' : 'Close'}</StyledP>
                    <StyledClose />
                </FlexItem>
            </div>
        </Wrapper>
    )
}

export default SearchButton;

const Wrapper = styled.div`
    display: flex;
    cursor: pointer;
    height: 28px;
    overflow: hidden;
    padding-left: 32px;
`

const FlexItem = styled.div`
    display: flex;
    transform: ${props => props.transform ? 'translateY(-100%)}' : 'translateY(0)'};
    ${props => props.theme.variables.transition}
    transition-duration: 260ms;
    
    &:hover {
        p, svg {
            opacity: .8;
        }
    }
`

const StyledP = styled.p`
    user-select: none;
    ${props => props.theme.fonts.h4};
    margin-right: 6px;
`

const StyledSearch = styled(SearchIcon)`
    width: 22px;
    align-self: flex-start;
    margin-top: 6px;
    margin-left: 4px;
`

const StyledClose = styled(CloseIcon)`
    width: 18px;
    align-self: center;
    margin-top: 2px;
`