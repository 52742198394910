import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import Divider from 'components/Divider';
import Tag from 'components/partials/Tag';

import Media from 'css/Media';

export const tagListQuery = pageType =>`
    ... on ${pageType}_Modules_Builder_TagListModule {
        fieldGroupName
        tags {
            name
        }
    }
`

export default function TagListModule(props) {
    let [isMobile, setMobile] = useState(window.innerWidth < 900);

    useEffect(() => {
        const handleResize = () => setMobile(window.innerWidth < 900);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    if(!props.data) return null;
    let { tags } = props.data;

    return (
        <Wrapper>
            <Container>
                {!isMobile ?
                    <GridElement
                        colStart={{default: 2, s: 5}}
                        colEnd={{default: 13, s: 24}}
                        rowStart={1}
                    >
                        <Divider />
                    </GridElement>
                : null}
                <StyledGridElement
                    colStart={{default: 2, s: 5}}
                    colEnd={{default: 13, s: 24}}
                    rowStart={2}
                >
                    { tags.map((tag, idx) => {
                        return (
                            <StyledTag tag={tag} idx={idx} key={idx} />
                        )
                    })}
                </StyledGridElement>
                {!isMobile ?
                    <GridElement
                        colStart={{default: 2, s: 5}}
                        colEnd={{default: 13, s: 24}}
                        rowStart={3}
                    >
                        <Divider />
                    </GridElement>
                : null}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    h2 {
        ${props => props.theme.fonts.h2}
        margin-bottom: 60px;
    }

    ${Media.greaterThan('s')`
        margin: 128px 0;
    `}
`

const StyledGridElement = styled(GridElement)`
    display: flex;

    ${Media.greaterThan('s')`
        justify-content: flex-start;
    `}
`

const StyledTag = styled(Tag)`
    display: inline-block;
`