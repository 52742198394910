import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import Media from 'css/Media';

const Hamburger = props => {
    const isLight = useSelector(state => state.headerColor.light);
    
    const clickHandler = () => {
        props.clickHandler();
    }

    return (
        <Wrapper
            onClick={() => clickHandler()}
            open={props.open}
            isLight={isLight}
            isVisible={props.isVisible}
        >
            <span className="center" />
        </Wrapper>
    )
}

export default Hamburger;

const Wrapper = styled.button`
    margin-top: -3px;
    position: relative;
    cursor: pointer;
    width: 33px;
    height: 22px;
    align-self: center;
    margin: auto 0 0;
    border: none;
    background-color: transparent;

    &:active,
    &:visited,
    &:focus {
        outline: none;
    }

    span, &:before, &:after {
        content: '';
        width: 33px;
        height: 1px;
        background: ${props => props.isLight ? props.open ? props.theme.colors.dark : props.theme.colors.negative : props.theme.colors.dark};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(0) translateX(-50%);
        display: block;
        ${props => props.theme.variables.transition}
        transition-duration: 260ms;
        transform-origin: 50% 50%;

        ${Media.greaterThan('s')`
            width: 56px;
            background: ${props => props.theme.colors.dark};
        `}
    }

    &:before { 
        transform: translateY(-11px) translateX(-50%); 

        ${Media.greaterThan('s')`
            transform: translateY(-14px) translateX(-50%); 
        `}
    }

    &:after { 
        transform: translateY(11px) translateX(-50%);

        ${Media.greaterThan('s')`
            transform: translateY(13px) translateX(-50%);
        `}
    }
    
    ${props => props.open && css`
        margin-left: 0;
        
        &:before {
            transform: translateX(-50%) rotate(45deg);
        }

        &:after {
            transform: translateX(-50%) rotate(-45deg);
        }

        span.center {
            opacity: 0;
        }
    `}

    display: ${props => props.isVisible ? 'block' : 'none'};

    ${Media.greaterThan('s')`
        display: block;
        width: 56px;
        height: 28px;
    `}
`