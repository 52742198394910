import React from 'react';
import styled from 'styled-components';

import { dispatch } from "../../redux";

export default props => {
    const { tag } = props;

    const toggleSearch = () => {
        dispatch("openSearch/toggle");
        dispatch("openMenu/clear");
        dispatch("searchInput/update", tag.name)
        //pass on the tag value for search input
    }

    return (
        <Tag onClick={toggleSearch} className={props.className}>{tag.name}</Tag>
    )
}

const Tag = styled.a`
    ${props => props.theme.fonts.h9}
    display: block;
    cursor: pointer;
    background-color: ${props => props.theme.colors.button};
    padding: 7px 16px 9px;
    border-radius: 36px;
    margin: 16px 8px 16px 0;
    ${props => props.theme.variables.transition}
    transition-duration: 260ms;

    &:hover,
    &:focus,
    &:active {
        background-color: ${props => props.theme.colors.buttonHover};
    }
`