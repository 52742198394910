import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";

import Media from "css/Media";

function LangSwitcher() {
  let lang = useSelector((state) => state.lang);
  let [language, setLanguage] = useState(lang === "no" ? "English" : "Norsk");
  let currentView = useSelector((state) => state.currentView);
  const switchLanguage = () => {
    if (lang === "en") {
      dispatch("lang/no");
      setLanguage("English");
    }

    if (lang === "no") {
      dispatch("lang/en");
      setLanguage("Norsk");
    }

    let redirectPath;

    // set the pll_language cookie to new value
    let now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    document.cookie = `pll_language=${lang}; expires=${now.toGMTString()}; path=/;`;

    // if current view translation info is available, else site just reloads.
    if (currentView) {
      redirectPath =
        lang === "no"
          ? currentView.en
            ? currentView.en
            : currentView.no
          : currentView.no
          ? currentView.no
          : currentView.en;
    }

    //remove hash
    redirectPath = redirectPath.split("#")[0];

    // redirect to corresponding translation or home page
    window.location.replace(redirectPath);
  };

  return (
    <Wrapper>
      <StyledA onClick={() => switchLanguage()}>{language}</StyledA>
    </Wrapper>
  );
}

export default LangSwitcher;

const Wrapper = styled.div`
  cursor: pointer;
  height: 28px;
  overflow: hidden;
`;

const StyledA = styled.a`
  display: block;
  ${(props) => props.theme.fonts.h7};
  position: relative;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  ${Media.greaterThan("s")`
        ${(props) => props.theme.fonts.h4}
    `}
`;
