export const setItemParams = (type, data) => {
  let arr = [];
  data[type].edges.forEach((entry) => {
    let isEvent;
    if (entry.node.eventData) {
      isEvent = true;
    } else isEvent = false;
    arr.push({
      data: entry.node,
      isEvent: isEvent,
      date: isEvent
        ? entry.node.eventData.eventDate
        : entry.node.festivalData.dateStart,
      dateEnd: isEvent ? null : entry.node.festivalData.dateEnd,
      chapter:
        entry.node.chaptersTags && entry.node.chaptersTags.nodes[0]
          ? entry.node.chaptersTags.nodes[0].name
          : null,
      city: isEvent
        ? entry.node.eventData.chapter
        : entry.node.festivalData.chapter,
      types: entry.node.performanceTypeTags
        ? entry.node.performanceTypeTags.nodes
        : [],
    });
  });
  return arr;
};
