import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { dispatch } from '../../../redux';

import PageTitleModule from 'components/modules/PageTitleModule';
import AlgoliaSearch from './AlgoliaSearch';

import useIsMobile from 'hooks/useIsMobile';

const Search = props => {
    let isMobile = useIsMobile();
    let lang = useSelector(state => state.lang);
    let location = window.location.pathname;

    useEffect(() => {
        if (props.open) {
            document.getElementsByTagName('body')[0].style.overflowY = 'hidden';

        }
        return(() => {
            document.getElementsByTagName('body')[0].style.overflowY = 'visible';
        })
        
    }, [props.open]);

    useEffect(() => {
        if (location) {
            closeSearch();
        }
        
    }, [location]);

    useEffect(() => {
        document.addEventListener("keydown", function(event) {
            if(event.keyCode === 27){
               //Esc key was pressed
               closeSearch();
           }
        });
        return(() => {
            document.removeEventListener("keydown", function(event) {
            })
        })
    },[])

    const closeSearch = () => {
        dispatch('openSearch/clear')
    }   
    
    return (
        <Wrapper open={props.open} isMobile={isMobile}>
            <StyledDiv open={props.open}>
                <PageTitleModule data={lang === 'no' ? 
                        {title: 'Hva leter du etter?'} : 
                        {title: 'What are you looking for?'}
                    } 
                />
            </StyledDiv>
            <StyledDiv open={props.open}>
                <AlgoliaSearch open={props.open} lang={lang} />
            </StyledDiv>
        </Wrapper>
    )
}

export default Search;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 40px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    z-index: ${props => props.open ? 10 : -10};
    opacity: ${props => props.open ? 1 : 0};
    ${props => props.theme.variables.transition}
    transition-property: opacity;
    transition-duration: 260ms;
    overflow-y: scroll;
`


const StyledDiv = styled.div`
    opacity: ${props => props.open ? 1 : 0};
    ${props => props.theme.variables.transition}
    transition-delay: 265ms;
    transition-duration: 260ms;
    transition-property: opacity;
`