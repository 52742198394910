import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment-mini";
import useIsMountedRef from "hooks/useIsMountedRef";
import Container from "components/Container";
import GridElement from "components/GridElement";
import EventThumbnail from "components/partials/EventThumbnail";
import Media from "css/Media";
import ThumbnailList from "../partials/ThumbnailList";

export const eventListQuery = (pageType) => `
  ... on ${pageType}_Modules_Builder_EventListModule {
    fieldGroupName
  }
`;

const moduleQuery = (pageType, capitalizedPageType, slug) => `
  query MyQuery {
    ${pageType}(id: "${slug}", idType: URI) {
      modules{
        builder{
          ... on ${capitalizedPageType}_Modules_Builder_EventListModule {
            fieldGroupName
            select
            showTime
            title
            chapter {
              name
            }
            selectEvents {
              ... on Event {
                title
                uri
                eventData {
                  chapter
                  eventDate
                  doorsOpen
                  eventStart
                  venue
                }
                metaTags(last: 3) {
                  nodes {
                    name
                    slug
                  }
                }
                thumbnailData {
                  featuredImage {
                      mediaItemUrl
                  }
                }
              }
              ... on Festival {
                title
                uri
                festivalData {
                  chapter
                  dateEnd
                  dateStart
                  venue
                }
                thumbnailData {
                  featuredImage {
                    mediaItemUrl
                  }
                }
                metaTags(last: 3) {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const eventQuery = (lang, date, terms) => `
  query MySecondQuery {
    events(where: {
      language: ${lang},
      orderby: {
        field: MENU_ORDER,
        order: ASC
      }, 
      metaQuery: {
        metaArray: {
          key: "event_date", 
          value: "${date}", 
          compare: GREATER_THAN_OR_EQUAL_TO, 
          type: DATE
        }
      }, 
      taxQuery: {
        relation: AND, 
        taxArray: {
          taxonomy: CHAPTERTAG, 
          terms: [${terms.map((t) => `"${t}"`).join(",")}],
          field: NAME
        }
      }
    }) {
      edges {
        node {
          title
          uri
          id
          eventData {
            chapter
            eventDate
            doorsOpen
            eventStart
            venue
          }
          thumbnailData {
          featuredImage {
            mediaItemUrl
          }
          }
          metaTags {
            nodes {
              name
              slug
            }
          }
          translation(language: EN) {
            id
          }
        }
      }
    }
    festivals(where: {  
      language: ${lang},
      orderby: {
        field: MENU_ORDER,
        order: ASC
      }, 
      metaQuery: {
        metaArray: {
          key: "date_start", 
          value: "${date}", 
          compare: GREATER_THAN_OR_EQUAL_TO, 
          type: DATE
        }
      }, 
      taxQuery: {
        relation: AND, 
        taxArray: {
          taxonomy: CHAPTERTAG, 
          terms: [${terms.map((t) => `"${t}"`).join(",")}], 
          field: NAME
        }
      }
    }) {
      edges {
        node {
          title
          uri
          id
          festivalData {
              chapter
              dateEnd
              dateStart
              venue
          }
          thumbnailData {
              featuredImage {
                  mediaItemUrl
              }
          }
          metaTags(last: 3) {
              nodes {
                  name
                  slug
              }
          }
          translation(language: EN) {
              id
          }
        }
      }
    }
  }
`;

async function getModuleData(pageType, slug) {
  let capitalizedPageType = pageType[0].toUpperCase() + pageType.substring(1);
  pageType =
    pageType === "newsArticle" ? "newsArticle" : pageType.toLowerCase();

  const moduleResp = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: moduleQuery(pageType, capitalizedPageType, slug),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  return moduleResp;
}

async function getEventData(lang, terms) {
  const dateStr = moment(new Date()).format("YYYYMMDD");
  const eventResp = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: eventQuery(lang, dateStr, terms),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  return eventResp;
}

export default function EventListModule(props) {
  //params needed to specify query params
  let lang = useSelector((state) => state.lang);
  let slug =
    window.location.pathname === "/"
      ? lang === "no"
        ? "home"
        : "home-2"
      : window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1
        );
  const [events, setEvents] = useState();
  const [moduleData, setModuleData] = useState(null);
  const [data, setData] = useState(null);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    //we run this query first to get user input if events should be listed by chapter
    getModuleData(props.pageType, slug).then((res) => isMountedRef.current && setModuleData(res));
  }, [props.pageType, slug, isMountedRef]);

  useEffect(() => {
    //runs after we fetch moduleData
    let page = props.pageType[0].toLowerCase() + props.pageType.substring(1);
    if (moduleData && moduleData[page]) {
      //we filter the array to only contain the module we're looking for
      let module = moduleData[page].modules.builder.filter(
        (m) =>
          m.fieldGroupName ===
          `${props.pageType}_Modules_Builder_EventListModule`
      );
      //we only run second query if events should be populated automatically
      if (module[props.typeIndex]?.select === "chapter") {
        let terms = [module[props.typeIndex].chapter.name];
        getEventData(lang.toUpperCase(), terms).then((res) =>
          handleChapterFilter(res)
        );
      } else {
        isMountedRef.current && setEvents(module[props.typeIndex]?.selectEvents);
      }
	  isMountedRef.current && setData(module[props.typeIndex]);
    }
  }, [moduleData, props.pageType, lang, props.typeIndex, isMountedRef]);

  const handleChapterFilter = (data) => {
    let allEvents = [];
    data.events.edges.map((item) => allEvents.push(item.node));
    data.festivals.edges.map((item) => allEvents.push(item.node));
    allEvents = allEvents.slice(0, 3);
    setEvents(allEvents);
  };

  if (!events || events.length === 0) return null;
  return (
    <Wrapper>
      {data.title ? (
        <Container>
          <GridElement
            colStart={{ default: 2, s: 5 }}
            colEnd={{ default: 13, s: 24 }}
          >
            <h2>{data.title}</h2>
          </GridElement>
        </Container>
      ) : null}
      <ThumbnailList mobileFullWidth>
        {events?.map((item, idx) => {
		  if(!item) return null;
          let type = "event";
          let showTime = data.showTime;
          if (item.festivalData) {
            type = "festival";
          }
          return (
            <EventThumbnail
              item={item}
              type={type}
              key={idx}
              showTime={showTime}
              isList
            />
          );
        })}
      </ThumbnailList>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  h2 {
    ${(props) => props.theme.fonts.h2}
    margin-bottom: 32px;

    ${Media.greaterThan("s")`
            margin-bottom: 78px;
        `}
  }

  ${Media.greaterThan("s")`
        margin-bottom: 36px;
    `}
`;
