import { pageTitleQuery } from "../components/modules/PageTitleModule";
import { imageQuery } from "../components/modules/ImageModule";
import { dividerQuery } from "../components/modules/DividerModule";
import { imageCarouselQuery } from "../components/modules/ImageCarouselModule";
import { logoListQuery } from "../components/modules/LogoListModule";
import { quoteQuery } from "../components/modules/QuoteModule";
import { tableQuery } from "../components/modules/TableModule";
import { videoQuery } from "../components/modules/VideoModule";
import { richTextQuery } from "../components/modules/RichTextModule";
import { PDFLinkListQuery } from "../components/modules/PDFLinkListModule";
import { featuredArticleQuery } from "../components/modules/FeaturedArticleModule";
import { articleListQuery } from "../components/modules/ArticleListModule";
import { eventListQuery } from "../components/modules/EventListModule";
import { tagListQuery } from "../components/modules/TagListModule";
import { quickLinkQuery } from "../components/modules/QuickLinkModule";

export const modulesQuery = (pageType) => `
	modules{
		builder{
            ${pageTitleQuery(pageType)}
            ${imageQuery(pageType)}
            ${dividerQuery(pageType)}
            ${imageCarouselQuery(pageType)}
            ${logoListQuery(pageType)}
            ${quoteQuery(pageType)}
            ${tableQuery(pageType)}
            ${videoQuery(pageType)}
            ${richTextQuery(pageType)}
            ${PDFLinkListQuery(pageType)}
            ${featuredArticleQuery(pageType)}
            ${articleListQuery(pageType)}
            ${eventListQuery(pageType)}
            ${quickLinkQuery(pageType)}
            ${tagListQuery(pageType)}
		}
	}
`;
