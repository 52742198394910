import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';

import Media from 'css/Media';

export const quoteQuery = pageType => `
    ... on ${pageType}_Modules_Builder_QuoteModule {
        author
        fieldGroupName
        quoteText
    }
`

export default function QuoteModule(props) {
    
    const { data } = props;
    return (
        <StyledContainer>
            <GridElement
                colStart={{default: 4, s: 5}}
                colEnd={{default: 13, s: 12}}
                rowStart={1}
            >
                <p>{data.quoteText}</p>
            </GridElement>
            {data.author ?
                <GridElement
                    colStart={{default: 4, s: 5}}
                    colEnd={{default: 13, s: 12}}
                    rowStart={2}
                >
                    <span>— {data.author}</span>
                </GridElement>
            : null}
        </StyledContainer>
    )
}

const StyledContainer = styled(Container)`
    padding: 32px 0;

    p {
        font-family: 'RightGrotesk', sans-serif;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
    }

    span {
        display: block;
        color: ${props => props.theme.colors.alt};
        ${props => props.theme.fonts.h8}
    }

    ${Media.greaterThan('s')`
        padding: 64px 0;

        p {
            ${props => props.theme.fonts.h2}
        }

        span {
            ${props => props.theme.fonts.h7}
        }
    `}
`