import React from 'react';
import styled from 'styled-components';

import FadeIn from 'components/partials/FadeIn';
import Container from 'components/Container';
import GridElement from 'components/GridElement';
import Image from 'components/Image';

import Media from 'css/Media';

export const imageQuery = pageType => `
    ... on ${pageType}_Modules_Builder_ImageModule {
        fieldGroupName
        images {
            format
            image {
                mediaItemUrl
            }
            video {
                mediaItemUrl
            }
            caption
        }
    }
`;

export default function ImageModule(props) {
    const { data } = props;
    
    return (
        <StyledContainer>
            { data.images.length < 2 ?
                <StyledGridElement
                    colStart={{default: 2, s: 9}}
                    colEnd={{default: 13, s: 24}}
                >
                    { data.images[0].format === 'image' ?
                        <FadeIn shouldScale>
                            <Image src={data.images[0].image.mediaItemUrl} />
                        </FadeIn>
                    :
                        <FadeIn shouldScale>
                            <VideoContainer>
                                <StyledVideo loop muted playsInline autoPlay src={data.images[0].video.mediaItemUrl}>Your browser does not support the video tag.</StyledVideo>
                            </VideoContainer>
                        </FadeIn>
                    }
                    <StyledP>{data.images[0].caption}</StyledP>
                </StyledGridElement>
            : 
                data.images.map((item, idx) => {
                    return (
                        <StyledGridElement
                            colStart={{default: idx === 0 ? 2 : 8, s: idx === 0 ? 5 : 15}}
                            colEnd={{default: idx === 0 ? 7 : 13, s: idx === 0 ? 14 : 24}}
                            rowStart={1}
                            key={idx}
                        >
                            { item.format === 'image' ?
                                <FadeIn shouldScale>
                                    <Image src={item.image.mediaItemUrl} />
                                </FadeIn>
                            :   
                                <FadeIn shouldScale>
                                    <VideoContainer>
                                        <StyledVideo loop muted playsInline autoPlay src={item.video.mediaItemUrl}>Your browser does not support the video tag.</StyledVideo>
                                    </VideoContainer>
                                </FadeIn>
                             }
                            {item.caption ? <StyledP>{item.caption}</StyledP> : null}
                        </StyledGridElement>
                    )
                })
            }
        </StyledContainer>
    )
}

const StyledContainer = styled(Container)`
    padding-bottom: 32px;

    ${Media.greaterThan('s')`
        padding-bottom: 64px;
    `}
`

const StyledGridElement = styled(GridElement)`
    img {
        max-width: none;
        width: 100%;
        padding-bottom: 8px;
        ${Media.greaterThan('s')`
            padding-bottom: 23px;
        `}
    }
`

const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const StyledVideo = styled.video`
    object-fit: cover;
    width:100%;
    height:100%;
`

const StyledP = styled.p`
    ${props => props.theme.fonts.h8}
    text-transform: none;
    color: #707070;

    ${Media.greaterThan('s')`
        ${props => props.theme.fonts.h7}
    `}
`