import React from 'react';
import styled from 'styled-components';
import { connectHits } from 'react-instantsearch-core';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import ThumbnailList from 'components/partials/ThumbnailList';
import Hit from './Hit';

import Media from 'css/Media';

const typeMap = {
  'page': {
    no: 'Sider',
    en: 'Pages',
  },
  'events': {
    no: 'Arrangementer',
    en: 'Events',
  },
  'news': {
    no: 'Nyheter',
    en: 'News',
  },
  'festivals': {
    no: 'Festivaler',
    en: 'Festivals',
  },
  'chapters': {
    no: 'Avdelinger',
    en: 'Chapters',
  },
}

const Hits = ({ lang, hits }) => {

  if(!hits || hits.length === 0) return null;

  const groupedHits = hits.filter(hit => {
        return hit.lang === lang;
  }).reduce((result, hit) => {
        result[hit.type] = result[hit.type] || [];
        result[hit.type].push(hit);
        return result;
  }, Object.create(null))

  return (
    <div>
        { Object.entries(groupedHits).map(group => {
            const type = group[0];
            const hits = group[1];
            return (
                <Group key={type}>
                    <Container>
                        <GridElement
                            colStart={{default: 2, s: 5}}
                            colEnd={{default: 13, s: 24}}
                        >
                            <h5>{ typeMap[type][lang] }</h5>
                        </GridElement>
                    </Container>
                    <ThumbnailList>
                        { hits.map(hit => <Hit key={hit.objectID} hit={ hit }/>) }
                    </ThumbnailList>
                </Group>
            );
        })}
    </div>
  )
}

export default connectHits(Hits);

const Group = styled.div`
    margin-bottom: 32px;

    h5 {
        ${props => props.theme.fonts.h2}
        margin-bottom: 32px;

        ${Media.greaterThan('s')`
            margin-bottom: 78px;
        `}
    }

    ${Media.greaterThan('s')`
        margin-bottom: 78px;
    `}
`
