import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import debounce from "lodash.debounce";
import Container from "components/Container";

import Media from "css/Media";
import useIsMountedRef from "hooks/useIsMountedRef";

const ThumbnailList = (props) => {
  const [elementWidth, setElementWidth] = useState(0);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const handleResize = () => {
      let el = document.getElementById("item-0");
      if (el && isMountedRef.current) setElementWidth(el.getBoundingClientRect().width);
    };
    handleResize();
    window.addEventListener("resize", debounce(handleResize), 500);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Container>
      {props.children.map((item, idx) => {
        return (
          <StyledDiv
            id={"item-" + idx}
            key={idx}
            width={elementWidth}
            fullWidth={props.mobileFullWidth ? 1 : 0}
            length={props.children.length}
            className={props.className}
          >
            {item}
          </StyledDiv>
        );
      })}
    </Container>
  );
};

export default ThumbnailList;

const StyledDiv = styled.div`
  > img {
    max-width: none;
  }
  -ms-grid-column: 2;
  -ms-grid-column-span: 11;
  grid-column-start: 2;
  grid-column-end: 13;

  ${(props) =>
    !props.fullWidth &&
    css`
      &:nth-of-type(odd) {
        -ms-grid-column: 2;
        -ms-grid-column-span: 5;
        grid-column-start: 2;
        grid-column-end: 7;
      }
      &:nth-of-type(even) {
        -ms-grid-column: 8;
        -ms-grid-column-span: 5;
        grid-column-start: 8;
        grid-column-end: 13;
      }
    `}

  ${Media.greaterThan("s")`
      &:nth-of-type(3n+1) {
        -ms-grid-column: 5;
        -ms-grid-column-span: 5;
        grid-column-start: 5;
        grid-column-end: 10;
      }
      &:nth-of-type(3n+2) {
        visibility: ${(p) => (p.width > 0 ? "visible" : "hidden")};
        width: ${(props) => props.width}px;
        justify-self: center;
        -ms-grid-column: 11;
        -ms-grid-column-span: 7;
        grid-column-start: 11;
        grid-column-end: 18;
        text-align: center;
      }

      &:nth-of-type(3n+3) {
        -ms-grid-column: 19;
        -ms-grid-column-span: 5;
        grid-column-start: 19;
        grid-column-end: 24;
      }
  `}
`;
