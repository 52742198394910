const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "currentView/update":
            return { ...state, ...payload };
        case "currentView/clear":
        case "REDUX/CLEAR":
            return defaultState;
        default:
            return state;
    }
};
