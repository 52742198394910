import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux";
import { RequestProvider } from "kb-gql";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { navigate } from "hookrouter";
import { HelmetProvider } from "react-helmet-async";

import Routes from "Router";
import MasterLayout from "views/MasterLayout";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_CODE) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
  ReactGA.set({ anonymizeIp: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

if (process.env.REACT_APP_FACEBOOK_ANALYTICS_CODE) {
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_ANALYTICS_CODE);
  ReactPixel.pageView();
}

function App() {
  React.useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const siteUrl = process.env.REACT_APP_SITE_URL;
        if (e.target.tagName === "A") {
          if (e.target.target === "_blank") return;
          const href = e.target.getAttribute("href");
          if (href && ~href.indexOf(siteUrl)) {
            e.preventDefault();
            let fixedHref = href.replace(siteUrl, "").replace(/^\/|\/$/g, "");
            if (fixedHref[0] !== "/") fixedHref = "/" + fixedHref;
            navigate(fixedHref);
            window.scrollTo(0, 0);
          }
        }
      },
      false
    );
  }, []);

  return (
    <Provider store={store}>
		<HelmetProvider>
			<RequestProvider graphqlUrl={process.env.REACT_APP_API_URL} delay={0}>
				<MasterLayout>
				<Routes />
				</MasterLayout>
			</RequestProvider>
		</HelmetProvider>
    </Provider>
  );
}

export default App;
