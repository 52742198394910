import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Image from "components/Image";
import A from "components/A";
import Overlay from "components/partials/ImageOverlay";
import FadeIn from "components/partials/FadeIn";
import RatioWrapper from "components/partials/RatioWrapper";

import ArrowSmall from "icons/ArrowSmall";

import Media from "css/Media";

export default function ArticleThumbnail(props) {
  const lang = useSelector((state) => state.lang);
  let href = props.url;

  return (
    <ArticleItem>
      <A href={href}>
        <FadeIn>
          <Overlay>
            <StyledRatioWrapper ratio="4:3">
              <StyledImage src={props.image} />
            </StyledRatioWrapper>
          </Overlay>
        </FadeIn>
        <Title>{props.title}</Title>
      </A>
      {props.description ? <p>{props.description}</p> : null}
      {!props.chapter ? (
        <span>
          <StyledArrowSmall />
          <A href={href}>
            <LinkText>{lang === "no" ? "Les mer" : "Read more"}</LinkText>
          </A>
        </span>
      ) : null}
    </ArticleItem>
  );
}

const ArticleItem = styled.div`
  text-align: left;
  margin-bottom: 64px;

  p {
    margin-bottom: 12px;
  }

  > p {
    ${(props) => props.theme.fonts.h7}
  }
`;

const LinkText = styled.span`
  ${(props) => props.theme.fonts.h7}
  border-color: transparent;
  ${(props) => props.theme.variables.transition}
  transition-duration: 250ms;

  &:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.dark};
  }

  ${Media.greaterThan("s")`
        border-color: transparent;
        
        &:hover {
            border-bottom: 1px solid ${(props) => props.theme.colors.dark};
        }
    `}
`;

const StyledRatioWrapper = styled(RatioWrapper)`
  margin-bottom: 16px;
`;

const StyledImage = styled(Image)`
  max-width: none;
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Title = styled.p`
  ${(props) => props.theme.fonts.h5}
`;

const StyledArrowSmall = styled(ArrowSmall)`
  transform: rotate(180deg);
  margin-right: 4px;
`;
