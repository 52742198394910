import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useIsMobile from "hooks/useIsMobile";

import Filterbar from "components/partials/FilterBar/FilterBar";
import DateInput from "components/partials/FilterBar/DateInput/DateInput";
import MobileFilter from "./MobileFilter";
import SelectItem from "components/partials/FilterBar/Select/SelectItem";

const ProgramFilter = (props) => {
  const [chapters, setChapters] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
	const getChapters = () => {
		return props.chapters.map((item) => item.name).sort();
	};

	const getPerformanceType = () => {
		return props.performanceTags.map((item) => item.name).sort();
	};
	setChapters(getChapters());
	setTypes(getPerformanceType());
  }, [props.data, props.chapters, props.performanceTags]);

  const handleSelectIsOpen = (bool) => {
    setSelectIsOpen(bool);
  };

  if (!props.data) return null;
  return (
    <React.Fragment>
      {!isMobile ? (
        <Filterbar>
          <DateInput
            setVisibility={props.setVisibility}
            handleDateChange={props.handleDateChange}
            showYear
            selectIsOpen={selectIsOpen}
          />
          {props.cities.length > 0 && (
            <StyledSelectItem
              arr={props.cities}
              handleChange={props.handleCityChange}
              name={props.lang === "no" ? "Sted" : "City"}
              setOpen={handleSelectIsOpen}
              setVisibility={props.setVisibility}
            />
          )}
		{chapters.length > 0 && (
            <StyledSelectItem
              arr={chapters}
              handleChange={props.handleChapterChange}
              name={props.lang === "no" ? "Avdeling" : "Chapter"}
              setOpen={handleSelectIsOpen}
              setVisibility={props.setVisibility}
            />
          )}
		{types.length > 0 && (
            <StyledSelectItem
              arr={types}
              handleChange={props.handleTypeChange}
              name={props.lang === "no" ? "Type arrangement" : "Type of event"}
              setOpen={handleSelectIsOpen}
              setVisibility={props.setVisibility}
            />
          )}
        </Filterbar>
      ) : (
        <MobileFilter
          cities={props.cities}
          chapters={chapters}
          types={types}
          handleDateChange={props.handleDateChange}
          handleCityChange={props.handleCityChange}
          handleChapterChange={props.handleChapterChange}
          handleTypeChange={props.handleTypeChange}
          setVisibility={props.setVisibility}
          lang={props.lang}
        />
      )}
    </React.Fragment>
  );
};

export default ProgramFilter;

const StyledSelectItem = styled(SelectItem)`
  margin-right: 32px;
`;
