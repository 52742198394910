import React, { useEffect } from "react";
import useRequest from "kb-gql";
import { useSelector } from "react-redux";
import { dispatch } from "../redux";

import ErrorBoundary from "components/partials/ErrorBoundary";
import Seo from "components/partials/Seo";
import PageLoader from "components/partials/PageLoader";
import Builder from "components/modules/Builder";
import ErrorPage from "views/ErrorPage";

import { seoQuery } from "hooks/useSeo";
import { modulesQuery } from "const/modulesQuery";

const annualReportsQuery = (lang) => `
    annualReports(where: {language: ${lang}}) {
        nodes {
            annual_reports {
                fieldGroupName
                title
                link {
                    mediaItemUrl
                }
                image {
                    mediaItemUrl
                }
            }
        }
    }
`;

const pageQuery = (slug, lang) => `
    page(id: "${slug}", idType: URI) {
        title
        content
        ${seoQuery}
        ${modulesQuery("Page")}
        translation(language: ${lang === "no" ? "EN" : "NO"}) {
            uri
        }
    }
`;

const assembleRequestSlug = (params) => {
  const { grandparent, parent, slug } = params;
  //check if grandparent or parent is present and assemble the request slug based on it
  return `${grandparent ? grandparent + "/" : ""}${
    parent ? parent + "/" : ""
  }${slug}`;
};

const Page = (params) => {
  const slug = assembleRequestSlug(params);
  const lang = useSelector((state) => state.lang);
  const data = useRequest(pageQuery(slug, lang), slug);
  const reports = useRequest(annualReportsQuery(lang.toUpperCase()), "reports");

  useEffect(() => {
    if (data)
      dispatch("currentView/update", {
        view: "page",
        no: lang === "no" ? slug : data.translation?.uri,
        en: lang === "no" ? data.translation?.uri : slug,
      });
	  return () => {
		  dispatch("currentView/clear");
	  };
  }, [data, lang, slug]);

  if (data === null) return <ErrorPage />;

  if (!data) return <PageLoader />;
  return (
    <section>
      <Seo
        title={data.title}
        keywords={data.thumbnailData.buzzwords}
        description={data.thumbnailData.description}
        featuredImage={data.thumbnailData.featuredImage?.mediaItemUrl}
        imgAlt={data.thumbnailData.featuredImage?.altText}
        imgWidth={data.thumbnailData.featuredImage?.mediaDetails.width}
        imgHeight={data.thumbnailData.featuredImage?.mediaDetails.height}
        published={data.date}
        modified={data.modified}
      />
      <ErrorBoundary for="Builder">
        <Builder
          modules={data.modules.builder}
          reports={reports.nodes}
          pageType="Page"
        />
      </ErrorBoundary>
    </section>
  );
};

export default Page;
