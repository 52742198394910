import { combineReducers } from "redux";
import request from "./reducers/requestReducer";
import response from "./reducers/responseReducer";
import headerColor from "./reducers/headerColorReducer";
import menuState from "./reducers/menuStateReducer";
import searchState from "./reducers/searchStateReducer";
import lang from "./reducers/languageReducer";
import chapter from "./reducers/chapterReducer";
import isFeatured from "./reducers/featuredReducer";
import currentView from "./reducers/currentViewReducer";
import scrollHeight from "./reducers/scrollHeightReducer";
import searchInput from "./reducers/searchInputReducer";
import backgroundOpacity from "./reducers/backgroundOpacityReducer";

//REDUCER_IMPORTS

const reducers = combineReducers({
    request,
    response,
    headerColor,
    menuState,
    searchState,
    lang,
    chapter,
    isFeatured,
    currentView,
    scrollHeight,
    searchInput,
    backgroundOpacity,
    //REDUCER_COMBINES
});

export default reducers;
