import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Container from "components/Container";
import GridElement from "components/GridElement";
import A from "components/A";

const Footer = (props) => {
  const { siteSettings, footerData, isVisible } = props;
  const isBlurry = useSelector((state) => state.backgroundOpacity);

  if (!footerData || !siteSettings) return null;
  let items = footerData.footerItems;
  if (props.lang === "en") items = footerData.footerItemsEn;

  return (
    <Wrapper isVisible={isVisible} isBlurry={isBlurry}>
      <Container>
        <GridElement colStart={5} colEnd={11} rowStart={1}>
          <NewsletterSignup>
            {props.lang === "no" ? (
              <StyledA
                href={siteSettings.newsletterSignupLink.url}
                target={siteSettings.newsletterSignupLink.target}
              >
                {siteSettings.newsletterSignupLink.title}
              </StyledA>
            ) : (
              <StyledA
                href={siteSettings.newsletterSignupLinkEn.url}
                target={siteSettings.newsletterSignupLinkEn.target}
              >
                {siteSettings.newsletterSignupLinkEn.title}
              </StyledA>
            )}
          </NewsletterSignup>
        </GridElement>
        {items.map((item, idx) => {
          return (
            <StyledDiv key={idx} id={"footerItem-" + idx}>
              <h5>{item.title}</h5>
              {item.content.map((i, ix) => {
                if (i.type === "text") {
                  return <p key={ix}>{i.text}</p>;
                } else
                  return (
                    <p key={ix}>
                      <A href={i.link.url} target={i.link.target}>
                        {i.link.title}
                      </A>
                    </p>
                  );
              })}
            </StyledDiv>
          );
        })}
        <CredentialsGridElement colStart={21} colEnd={24} rowStart={2}>
          <p>
            <A
              href={
                props.lang === "no"
                  ? siteSettings.privacyLink.url
                  : siteSettings.privacyLinkEn.url
              }
              target={
                props.lang === "no"
                  ? siteSettings.privacyLink.target
                  : siteSettings.privacyLinkEn.target
              }
            >
              {props.lang === "no"
                ? siteSettings.privacyLink.title
                : siteSettings.privacyLinkEn.title}
            </A>
          </p>
          <p>{new Date().getFullYear() + " © nyMusikk"}</p>
          <p>
            {props.lang === "no"
              ? siteSettings.orgNumber
              : siteSettings.orgNumberEn}
          </p>
        </CredentialsGridElement>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  padding: 150px 0 50px;
  opacity: ${(props) => (props.isVisible ? (props.isBlurry ? 0.05 : 1) : 0)};
  ${(props) => props.theme.variables.transition}

  p {
    ${(props) => props.theme.fonts.h7}
    padding-bottom: 10px;

    a {
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid ${(props) => props.theme.colors.dark};
      }
    }
  }

  h5 {
    ${(props) => props.theme.fonts.h5}
    margin-bottom: 20px;
  }
`;

const StyledA = styled.a`
  ${(props) => props.theme.fonts.h5}
  display: inline-block;
  position: relative;
  padding-bottom: 6px;
  white-space: nowrap;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #000000;
    bottom: 0;
    left: 0;
    ${(props) => props.theme.variables.transition}
    transition-duration: 250ms;
  }

  &:hover {
    &::after {
      background-color: transparent;
    }
  }

  a {
    &::after {
      position: absolute;
      content: "→";
      right: -20px;
    }
  }
`;

const NewsletterSignup = styled.div`
  margin-bottom: 150px;
  user-select: none;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row-start: 2;
  -ms-grid-column-span: 3;

  &#footerItem-0 {
    -ms-grid-column: 5;
    grid-column-start: 5;
    grid-column-end: 8;
  }
  &#footerItem-1 {
    -ms-grid-column: 9;
    grid-column-start: 9;
    grid-column-end: 12;
  }
  &#footerItem-2 {
    -ms-grid-column: 13;
    grid-column-start: 13;
    grid-column-end: 17;
  }
  &#footerItem-3 {
    -ms-grid-column: 17;
    grid-column-start: 17;
    grid-column-end: 20;
  }
`;

const CredentialsGridElement = styled(GridElement)`
  padding-top: 48px;
`;
