import React from "react";
import styled, { css } from "styled-components";

import Container from "components/Container";
import GridElement from "components/GridElement";
import MobileDateInput from "components/partials/FilterBar/DateInput/MobileDateInput";
import MobileSelectItem from "../../components/partials/FilterBar/Select/MobileSelectItem";

const MobileFilter = (props) => {
  return (
    <Wrapper>
      <Container grid>
        <StyledGridElement
          colStart={{ default: 1, s: 1 }}
          colEnd={{ default: 14, s: 24 }}
        >
          <ItemTitleWrapper>
            <MobileDateInput
              setVisibility={props.setVisibility}
              handleDateChange={props.handleDateChange}
              date={props.date}
              maxDate={props.maxDate}
              showYearDropdown
            />
          </ItemTitleWrapper>
          <StyledSelectItem
            arr={props.chapters}
            handleChange={props.handleChapterChange}
            name={props.lang === "no" ? "Avdeling" : "Chapter"}
            setVisibility={props.setVisibility}
          />
        </StyledGridElement>
      </Container>
    </Wrapper>
  );
};

export default MobileFilter;

const Wrapper = styled.section`
  user-select: none;
  border-bottom: 1px solid #000;
`;

const ItemTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid #000;

  > div:first-of-type {
    width: 100% !important;
  }

  svg {
    height: 16px;
  }

  h5 {
    ${(props) => props.theme.fonts.h7}
  }

  ${(props) =>
    props.open &&
    css`
      svg {
        transform: rotate(360deg);
      }
    `}
`;

const StyledGridElement = styled(GridElement)`
  border-top: 1px solid #000;
`;

const StyledSelectItem = styled(MobileSelectItem)`
  padding: 20px 0 4px;
  border-bottom: 1px solid #000;
`;
