import React, { useEffect } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { dispatch } from "../redux";
import { useSelector } from "react-redux";
import moment from "moment";

import ErrorBoundary from "components/partials/ErrorBoundary";
import Seo from "components/partials/Seo";
import Builder from "components/modules/Builder";
import DividerModule from "components/modules/DividerModule";

import Container from "components/Container";
import GridElement from "components/GridElement";
import Image from "components/Image";
import A from "components/A";
import RatioWrapper from "components/partials/RatioWrapper";
import PageLoader from "components/partials/PageLoader";
import FadeIn from "components/partials/FadeIn";

import ErrorPage from "views/ErrorPage";

import { seoQuery } from "hooks/useSeo";
import { modulesQuery } from "const/modulesQuery";

import Media from "css/Media";

const pageQuery = (slug, lang) => `
    event(id: "${slug}", idType: URI) {
        eventData {
            chapter
            eventDate
            doorsOpen
            eventStart
            fieldGroupName
            venue
            item {
                title
                input {
                    text
                    link
                    itemLink {
                        target
                        title
                        url
                    }
                }
            }
            format
            image {
                mediaItemUrl
            }
            video {
                mediaItemUrl
            }
            programInformation {
                workTitle
                yearPublished
                artists {
                  artist
                  instrument
                }
                composers {
                  composer
                }
            }
        }
        title
        thumbnailData {
            featuredImage {
                mediaItemUrl
            }
        }
        chaptersTags(last: 10) {
            nodes {
              name
            }
        }
        ${seoQuery}
        ${modulesQuery("Event")}
        translation(language: ${lang === "no" ? "EN" : "NO"}) {
            uri
        }
    }
`;

const TableItem = (props) => {
  if (props.url && props.title && props.text) {
    return (
      <TableContainer>
        <GridElement
          colStart={{ default: 2, s: 5 }}
          colEnd={{ default: 5, s: 8 }}
          rowStart={props.idx}
        >
          <Title>{props.title}</Title>
        </GridElement>
        <GridElement
          colStart={{ default: 6, s: 9 }}
          colEnd={{ default: 13, s: 24 }}
          rowStart={props.idx}
        >
          <Text>
            <A href={props.url} target="_blank">
              → {props.text}
            </A>
          </Text>
        </GridElement>
      </TableContainer>
    );
  } else if (props.input && props.title) {
    return (
      <TableContainer>
        <GridElement
          colStart={{ default: 2, s: 5 }}
          colEnd={{ default: 5, s: 8 }}
          rowStart={props.idx}
        >
          <Title>{props.title}</Title>
        </GridElement>
        {props.input.map((input, i) => {
          return input.link ? (
            <GridElement
              colStart={{ default: 6, s: 9 }}
              colEnd={{ default: 13, s: 24 }}
              rowStart={props.idx + i}
              key={i}
            >
              <Text>
                →{" "}
                <A
                  href={input.itemLink ? input.itemLink.url : null}
                  target={input.itemLink ? input.itemLink.target : null}
                >
                  {input.itemLink ? input.itemLink.title : null}
                </A>
              </Text>
            </GridElement>
          ) : (
            <GridElement
              colStart={{ default: 6, s: 9 }}
              colEnd={{ default: 13, s: 24 }}
              rowStart={props.idx + i}
              key={i}
            >
              {input.text ? <Text>{input.text}</Text> : null}
            </GridElement>
          );
        })}
      </TableContainer>
    );
  } else
    return (
      <TableContainer>
        <GridElement
          colStart={{ default: 2, s: 5 }}
          colEnd={{ default: 5, s: 8 }}
          rowStart={props.idx}
        >
          {props.title ? <Title>{props.title}</Title> : null}
        </GridElement>
        <GridElement
          colStart={{ default: 6, s: 9 }}
          colEnd={{ default: 13, s: 24 }}
          rowStart={props.idx}
        >
          {props.text ? <Text>{props.text}</Text> : null}
        </GridElement>
      </TableContainer>
    );
};

const ProgramInformationItem = (props) => {
  return (
    <ProgramItem>
      {props.composers.map((item, i) => {
        if (item.composer) {
          return (
            <Artist key={i}>
              {item.composer}
              {i === props.composers.length - 1 ? ": " : ", "}
            </Artist>
          );
        } else return null;
      })}
      {props.workTitle ? <span>{props.workTitle}</span> : null}
      {props.yearPublished ? (
        <span>{" (" + props.yearPublished + ")"}</span>
      ) : null}
      <div>
        {props.artists.map((item, i) => {
          return (
            <span key={i}>
              {item.artist ? (
                <Artist>
                  {item.artist}
                  {!item.instrument
                    ? i === props.artists.length - 1
                      ? null
                      : ", "
                    : " "}
                </Artist>
              ) : null}
              {item.instrument ? (
                <span>
                  ({item.instrument})
                  {i === props.artists.length - 1 ? "" : ", "}
                </span>
              ) : null}
            </span>
          );
        })}
      </div>
    </ProgramItem>
  );
};

const SingleEventPage = (params) => {
  const slug = params.slug;
  const lang = useSelector((state) => state.lang);
  const data = useRequest(pageQuery(slug, lang), slug);

  useEffect(() => {
    if (data) {
      if (
        data.chaptersTags &&
        data.chaptersTags.nodes[0] &&
        data.chaptersTags.nodes[0].name
      ) {
        dispatch("chapter/update", data.chaptersTags.nodes[0].name);
      }

      dispatch("currentView/update", {
        view: "event",
        no: lang === "no" ? slug : data.translation.uri,
        en: lang === "no" ? data.translation?.uri : slug,
      });
    }
    return () => {
      dispatch("chapter/clear");
      dispatch("currentView/clear");
    };
  }, [data, lang, slug]);

  if (data === null) return <ErrorPage />;
  if (!data) return <PageLoader />;

  return (
    <section>
      <Seo
        title={data.title}
        keywords={data.thumbnailData.buzzwords}
        description={data.thumbnailData.description}
        featuredImage={data.thumbnailData.featuredImage?.mediaItemUrl}
        imgAlt={data.thumbnailData.featuredImage?.altText}
        imgWidth={data.thumbnailData.featuredImage?.mediaDetails.width}
        imgHeight={data.thumbnailData.featuredImage?.mediaDetails.height}
        published={data.date}
        modified={data.modified}
      />
      <HeaderContainer>
        <GridElement
          colStart={{ default: 2, s: 5 }}
          colEnd={{ default: 4, s: 6 }}
        >
          {data.eventData.eventDate ? (
            <p>
              {lang === "no"
                ? moment(data.eventData.eventDate).format("DD.MM.YY")
                : moment(data.eventData.eventDate).format("MM.DD.YY")}
            </p>
          ) : null}
          {data.eventData.chapter ? <p>{data.eventData.chapter}</p> : null}
        </GridElement>
        <GridElement
          colStart={{ default: 6, s: 9 }}
          colEnd={{ default: 12, s: 18 }}
        >
          <h1>{data.title}</h1>
        </GridElement>
      </HeaderContainer>
      {data.eventData.format === "image" ? (
        <FadeIn shouldScale>
          {data.eventData.image && data.eventData.image.mediaItemUrl ? (
            <ImageContainer>
              <GridElement
                colStart={{ default: 1, s: 9 }}
                colEnd={{ default: 14, s: 24 }}
              >
                <RatioWrapper ratio="16:9">
                  <Image src={data.eventData.image.mediaItemUrl} />
                </RatioWrapper>
              </GridElement>
            </ImageContainer>
          ) : null}
        </FadeIn>
      ) : (
        <FadeIn shouldScale>
          {data.eventData.video && data.eventData.video.mediaItemUrl ? (
            <VideoContainer>
              <GridElement
                colStart={{ default: 1, s: 9 }}
                colEnd={{ default: 14, s: 24 }}
              >
                <StyledVideo
                  loop
                  muted
                  playsInline
                  autoPlay
                  src={data.eventData.video.mediaItemUrl}
                >
                  Your browser does not support the video tag.
                </StyledVideo>
              </GridElement>
            </VideoContainer>
          ) : null}
        </FadeIn>
      )}
      {data.eventData.venue ? (
        <TableItem
          title={lang === "no" ? "Scene" : "Venue"}
          idx={1}
          text={data.eventData.venue}
        />
      ) : null}
      {data.eventData.doorsOpen ? (
        <TableItem
          title={lang === "no" ? "Dørene åpner" : "Doors opening"}
          idx={2}
          text={data.eventData.doorsOpen.slice(
            0,
            data.eventData.doorsOpen.length - 3
          )}
        />
      ) : null}
      {data.eventData.eventStart ? (
        <TableItem
          title={lang === "no" ? "Starttid" : "Start time"}
          idx={3}
          text={data.eventData.eventStart.slice(
            0,
            data.eventData.eventStart.length - 3
          )}
        />
      ) : null}
      {data.eventData.item
        ? data.eventData.item.map((item, idx) => (
            <TableItem
              title={item.title}
              idx={idx + 4}
              text={item.text}
              url={item.url}
              input={item.input}
              key={idx}
            />
          ))
        : null}
      <DividerModule data={{ top: "m", bottom: "m" }} />
      {data.eventData.programInformation ? (
        <Container>
          <ProgramInformationGridElement
            colStart={{ default: 2, s: 11 }}
            colEnd={{ default: 13, s: 22 }}
          >
            {data.eventData.programInformation.map((item, i) => {
              return (
                <ProgramInformationItem
                  key={i}
                  workTitle={item.workTitle}
                  yearPublished={item.yearPublished}
                  composers={item.composers}
                  artists={item.artists}
                />
              );
            })}
          </ProgramInformationGridElement>
        </Container>
      ) : null}
      <ErrorBoundary for="Single Event Builder">
        <Builder modules={data.modules.builder} pageType="Event" />
      </ErrorBoundary>
    </section>
  );
};

export default SingleEventPage;

const HeaderContainer = styled(Container)`
  padding: 78px 0 32px;

  h1 {
    margin-top: -10px;
  }
  p {
    ${(props) => props.theme.fonts.h7}
  }

  ${Media.greaterThan("s")`
        padding: 0 30px 173px;

        h1 {
            margin-top: -14px;
        }
        p {
            ${(props) => props.theme.fonts.h4}
        }
`}
`;

const ImageContainer = styled(Container)`
  padding-bottom: 32px;

  ${Media.greaterThan("s")`
        padding-bottom: 64px;
    `}

  img {
    max-width: none;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    padding-bottom: 8px;
    ${Media.greaterThan("s")`
            padding-bottom: 23px;
        `}
  }
`;

const TableContainer = styled(Container)`
  p {
    padding-bottom: 8px;

    ${Media.greaterThan("s")`
            padding-bottom: 16px;
        `}
  }

  a {
    text-decoration: none;

    ${Media.greaterThan("s")`
            border-color: transparent;
            ${(props) => props.theme.variables.transition}
            transition-duration: 250ms;

            &:hover {
                border-bottom: 1px solid ${(props) => props.theme.colors.dark};
            }
        `}
  }
`;

const Title = styled.p`
  ${(props) => props.theme.fonts.h8}
  line-height: 20px;
  font-weight: 500;

  ${Media.greaterThan("s")`
        ${(props) => props.theme.fonts.h5}
        font-weight: 500;
    `}
`;

const Text = styled.p`
  ${(props) => props.theme.fonts.h8}
  line-height: 20px;

  ${Media.greaterThan("s")`
        ${(props) => props.theme.fonts.h5}
    `}
`;

const VideoContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 32px;

  ${Media.greaterThan("s")`
        padding-bottom: 64px;
    `}
`;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ProgramInformationGridElement = styled(GridElement)`
  margin-bottom: 32px;

  ${Media.greaterThan("s")`
        margin-bottom: 64px;
    `}
`;

const ProgramItem = styled.div`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    font-size: 15px;
    line-height: 25px;

    ${Media.greaterThan("s")`
            ${(props) => props.theme.fonts.h6}
        `}
  }
`;

const Composer = styled.span`
  font-weight: 500;
`;

const Artist = styled(Composer)``;
