//Get current language
export const currentLang = () => {
    return window.location.pathname.substr(1, 2) === 'en' ? 'en' : 'no';
}

// Redirect home
export const redirHome = () => {
    const path = currentLang() === 'no' ? '/' : '/en';
    window.location.replace(path);
}

// Redirect 404
export const redir404 = () => {
    const lang = currentLang();
    const ref = document.referrer || '/';
    let path = '/404?path='+window.location.pathname+'&from='+ref;
    if(lang === 'en') path = '/en/404?path='+window.location.pathname+'&from='+ref;
    window.location.replace(path);
}