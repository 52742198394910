import React, { useRef, useEffect, useState } from "react";

const IMGIX_URL = process.env.REACT_APP_IMGIX_URL;
const IMGPROXY_URL = process.env.REACT_APP_IMGPROXY_URL;
const IMGPROXY_SIGNATURE = process.env.REACT_APP_IMGPROXY_SIGNATURE || '_';

const getUrl = (url) => {
    if (!IMGIX_URL) return url;
    return url.replace(process.env.REACT_APP_API_BASE_URL, IMGIX_URL);
};

const getSizeUrl = (url, size) => {
    let prefix = url.indexOf("?") >= 0 ? "&" : "?";
    return `${getUrl(url)}${prefix}w=${size}&auto=format ${size}w`;
};

const getPreviewUrl = (url) => {
    let prefix = url.indexOf("?") >= 0 ? "&" : "?";
    return `${getUrl(url)}${prefix}w=150&blur=100&auto=format`;
};

const getSizeUrlNew = (url, size) => {
    if (!IMGPROXY_URL) return url;
    return `${IMGPROXY_URL}/${IMGPROXY_SIGNATURE}/width:${size}/plain/${url} ${size}w`
};

const getPreviewUrlNew = (url) => {
    if (!IMGPROXY_URL) return url;
    return `${IMGPROXY_URL}/${IMGPROXY_SIGNATURE}/width:150/blur:0.5/plain/${url}`
};

const Image = ({ src, sizeOverride, lazy, alt, ...props }) => {
    const imageRef = useRef(null);
    const [imageSize, setImageSize] = useState(null);
    useEffect(() => {
        if (imageRef.current && !imageSize) {
            setImageSize(imageRef.current.width);
        }
    }, [imageRef, imageSize]);
    if (!src) return null;
    return (
        <img
            ref={imageRef}
            src={getPreviewUrlNew(src)}
            loading={lazy || "lazy"}
            srcSet={[300, 500, 800, 1000, 1200, 1600]
                .map((i) => getSizeUrlNew(src, i))
                .join(", ")}
            sizes={`${sizeOverride || imageSize || 300}px`}
            alt={alt?.length > 0 ? alt : ""}
            {...props}
        />
    );
};

export default Image;
