import React, { useEffect } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { useSelector } from "react-redux";
import { dispatch } from "../redux";
import moment from "moment";

import ErrorBoundary from "components/partials/ErrorBoundary";
import Seo from "components/partials/Seo";
import RatioWrapper from "components/partials/RatioWrapper";
import Builder from "components/modules/Builder";
import DividerModule from "components/modules/DividerModule";
import TableModule from "components/modules/TableModule";

import Container from "components/Container";
import GridElement from "components/GridElement";
import Image from "components/Image";
import PageLoader from "components/partials/PageLoader";
import FadeIn from "components/partials/FadeIn";

import ErrorPage from "views/ErrorPage";

import { seoQuery } from "hooks/useSeo";
import { modulesQuery } from "const/modulesQuery";

import Media from "css/Media";

const pageQuery = (slug, lang) => `
    festival(id: "${slug}", idType: URI) {
        title
        festivalData {
            chapter
            dateEnd
            dateStart
            venue
            item {
                title
                input {
                    link
                    text
                    itemLink {
                        target
                        title
                        url
                    }
                }
            }
            format
            image {
                mediaItemUrl
            }
            video {
                mediaItemUrl
            }
        }
        thumbnailData {
            description
            featuredImage {
                mediaItemUrl
            }
        }
        chaptersTags(last: 10) {
            nodes {
              name
            }
        }
        ${seoQuery}
        ${modulesQuery("Festival")}
        translation(language: ${lang === "no" ? "EN" : "NO"}) {
            uri
        }
    }
`;

const SingleFestivalPage = (params) => {
  let slug = params.slug;
  const lang = useSelector((state) => state.lang);
  const data = useRequest(pageQuery(slug, lang), slug);

  useEffect(() => {
    if (data) {
      if (
        data.chaptersTags &&
        data.chaptersTags.nodes[0] &&
        data.chaptersTags.nodes[0].name
      ) {
        dispatch("chapter/update", data.chaptersTags.nodes[0].name);
      }
      dispatch("currentView/update", {
        view: "festival",
        no: lang === "no" ? slug : data.translation?.uri,
        en: lang === "no" ? data.translation?.uri : slug,
      });
    }

    return () => {
      dispatch("chapter/clear");
      dispatch("currentView/clear");
    };
  }, [data, lang, slug]);

  const getFestivalYear = () => {
    let yearStart = moment(data.festivalData.dateStart).format("YYYY");
    let yearEnd = moment(data.festivalData.dateEnd).format("YYYY");
    if (yearStart === yearEnd) {
      return yearStart;
    } else return yearStart + " - " + yearEnd;
  };
  if (data === null) return <ErrorPage />;
  if (!data) return <PageLoader />;

  return (
    <section>
      <Seo
        title={data.title}
        keywords={data.thumbnailData.buzzwords}
        description={data.thumbnailData.description}
        featuredImage={data.thumbnailData.featuredImage?.mediaItemUrl}
        imgAlt={data.thumbnailData.featuredImage?.altText}
        imgWidth={data.thumbnailData.featuredImage?.mediaDetails.width}
        imgHeight={data.thumbnailData.featuredImage?.mediaDetails.height}
        published={data.date}
        modified={data.modified}
      />
      <HeaderContainer>
        <HeaderGridElement
          colStart={{ default: 2, s: 5 }}
          colEnd={{ default: 4, s: 6 }}
        >
          {data.festivalData.dateStart && data.festivalData.dateEnd ? (
            <>
              <p>
                {moment(data.festivalData.dateStart).format("DD.MM") + " "}-
                {" " + moment(data.festivalData.dateEnd).format("DD.MM")}
              </p>
              <p>{getFestivalYear()}</p>
              {data.festivalData.chapter ? (
                <p>{data.festivalData.chapter}</p>
              ) : null}
            </>
          ) : null}
        </HeaderGridElement>
        <GridElement
          colStart={{ default: 6, s: 9 }}
          colEnd={{ default: 12, s: 18 }}
        >
          <h1>{data.title}</h1>
        </GridElement>
      </HeaderContainer>

      {data.festivalData.format === "image" ? (
        <FadeIn shouldScale>
          {data.festivalData.image && data.festivalData.image.mediaItemUrl ? (
            <ImageContainer>
              <GridElement
                colStart={{ default: 1, s: 9 }}
                colEnd={{ default: 14, s: 24 }}
              >
                <RatioWrapper ratio="16:9">
                  <Image src={data.festivalData.image.mediaItemUrl} />
                </RatioWrapper>
              </GridElement>
            </ImageContainer>
          ) : null}
        </FadeIn>
      ) : (
        <FadeIn shouldScale>
          {data.festivalData.video && data.festivalData.video.mediaItemUrl ? (
            <VideoContainer>
              <GridElement
                colStart={{ default: 1, s: 9 }}
                colEnd={{ default: 14, s: 24 }}
              >
                <StyledVideo
                  loop
                  muted
                  playsInline
                  autoPlay
                  src={data.festivalData.video.mediaItemUrl}
                >
                  Your browser does not support the video tag.
                </StyledVideo>
              </GridElement>
            </VideoContainer>
          ) : null}
        </FadeIn>
      )}
      {data.festivalData.item ? <TableModule data={data.festivalData} /> : null}
      <DividerModule data={{ top: "m", bottom: "m" }} />
      <ErrorBoundary for="Festival Builder">
        <Builder modules={data.modules.builder} pageType="Festival" />
      </ErrorBoundary>
    </section>
  );
};

export default SingleFestivalPage;

const HeaderContainer = styled(Container)`
  padding: 78px 0 32px;

  h1 {
    margin-top: -10px;
  }

  p {
    ${(props) => props.theme.fonts.h7}
  }

  ${Media.greaterThan("s")`
        padding: 0 30px 173px;

        h1 {
            margin-top: -14px;
        }
        p {
            ${(props) => props.theme.fonts.h4}
        }
    `}
`;

const HeaderGridElement = styled(GridElement)`
  > p:first-of-type {
    white-space: nowrap;
  }
`;

const ImageContainer = styled(Container)`
  padding-bottom: 32px;

  ${Media.greaterThan("s")`
        padding-bottom: 64px;
    `}

  img {
    max-width: none;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    margin-bottom: 8px;
    ${Media.greaterThan("s")`
            margin-bottom: 23px;
        `}
  }
`;

const VideoContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 32px;

  ${Media.greaterThan("s")`
        padding-bottom: 64px;
    `}
`;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
