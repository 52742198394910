import React from 'react';
import styled, { css } from 'styled-components';
import ChevronDown from 'icons/ChevronDown';

const Button = React.forwardRef(({
    value, 
    onClick, 
    open, 
    setOpen,
    setClickedOutside,
    pushedWhileOpen,
    setPushedWhileOpen,
    reverse
}, ref) => {

    //handler for pressing the date input to open the calendar
    const handleClick = () => {
        if(pushedWhileOpen) {
            setPushedWhileOpen(false)
        } else {
            setOpen();
            onClick();
        }
    }

    return (
        <FilterItem onClick={handleClick} reverse={reverse}>
            <Arrow open={open} reverse={reverse} /><span>{value}</span >
        </FilterItem>
    )
})

export default Button;

const FilterItem = styled.div`
    cursor: pointer;
    user-select: none;
    display: flex;

    span {
        ${props => props.theme.fonts.h4}
        order: 2;

        ${props => props.reverse && css`
            order: 1;
        `}
    }
`

const Arrow = styled(ChevronDown)`
    order: 1;
    align-self: center;
    ${props => props.theme.variables.transition}
    transition-duration: 350ms;
    transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
    margin-right: 6px;

    path {
        stroke: ${props => props.theme.colors.text};
    }

    ${props => props.reverse && css`
        margin-left: 6px;
        order: 2;
    `}
`