import useRequest from "kb-gql";

export const seoQuery = `
    title
    date
    modified
    thumbnailData {
        buzzwords
        description
        featuredImage {
        mediaItemUrl
            altText
            mediaDetails {
                width
                height
            }
        }
    }
`;

const globalSeoQuery = `
	seo {
		schema {
			siteName
			wordpressSiteName
			siteUrl
			companyName
			companyLogo {
				mediaItemUrl
			}
			logo {
				mediaItemUrl
			}
		}
	}
`;

export const useGlobalSeo = () => {
    return useRequest(globalSeoQuery, "_global_seo");
};
