const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "response/create":
            return { ...payload };
        case "response/update":
            return { ...state, ...payload };
        case "response/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};
