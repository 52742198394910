const defaultState = false;

export default (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case "isFeatured/setFeatured":
            return true;
        case "isFeatured/clear":
        case "REDUX/CLEAR":
            return defaultState;
        default:
            return state;
    }
};