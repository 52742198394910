import RGRegEot from 'css/fonts/rg-reg.eot';
import RGRegWoff2 from 'css/fonts/rg-reg.woff2';
import RGRegWoff from 'css/fonts/rg-reg.woff';

import RGTextEot from 'css/fonts/rg-text.eot';
import RGTextWoff2 from 'css/fonts/rg-text.woff2';
import RGTextWoff from 'css/fonts/rg-text.woff';

import RGMedEot from 'css/fonts/rg-med.eot';
import RGMedWoff2 from 'css/fonts/rg-med.woff2';
import RGMedWoff from 'css/fonts/rg-med.woff';

const Fonts = `
    @font-face {
        font-family: 'RightGrotesk';
        src: url(${RGTextEot}) format('embedded-opentype');
        src: url(${RGTextWoff2}) format('woff2'),
            url(${RGTextWoff}) format('woff');
        font-weight: 100;
        font-display: fallback;
    }

    @font-face {
        font-family: 'RightGrotesk';
        src: url(${RGMedEot}) format('embedded-opentype');
        src: url(${RGMedWoff2}) format('woff2'),
            url(${RGMedWoff}) format('woff');
        font-weight: 500;
        font-display: fallback;
    }

    @font-face {
        font-family: 'RightGrotesk';
        src: url(${RGRegEot}) format('embedded-opentype');
        src: url(${RGRegWoff2}) format('woff2'),
            url(${RGRegWoff}) format('woff');
        font-weight: normal;
        font-display: fallback;
    }
`

export default Fonts;