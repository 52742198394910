import React from 'react';
import styled from 'styled-components';

import Media from '../css/Media';

export default props => {

    return (
        <Container 
            rows={props.rows} 
            fixed={props.fixed} 
            className={props.className}
        >
            {props.children}
        </Container>
    )
}


const Container = styled.div`
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (.5fr 1fr)[6] .5fr;
    grid-template-columns: repeat(6, .5fr 1fr) .5fr;
    -ms-grid-rows: ${props => props.rows || 'auto'};
    grid-template-rows: ${props => props.rows || 'auto'};
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0;

    ${Media.greaterThan('s')`
        position: ${props => props.fixed ? 'fixed' : 'relative'};
        -ms-grid-columns: (1fr .1702fr)[11] 1fr;
        grid-template-columns: repeat(11, 1fr .1702fr) 1fr;
        padding: 0 30px;
    `}
`