import React, { useState, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import PlusIcon from "icons/PlusIcon";
import MinusIcon from "icons/MinusIcon";

const Button = React.forwardRef(
  (
    {
      value,
      onClick,
      open,
      setOpen,
      pushedWhileOpen,
      setPushedWhileOpen,
    },
    ref
  ) => {
    //handler for pressing the date input to open the calendar
    const handleClick = () => {
      if (pushedWhileOpen) {
        setPushedWhileOpen(false);
      } else {
        setOpen();
        onClick();
      }
    };

    return (
      <FilterItem onClick={handleClick} ref={ref}>
        <span>{value}</span>
        {open ? <MinusIcon /> : <PlusIcon />}
      </FilterItem>
    );
  }
);

const CalendarContainer = ({ className, children }) => (
  <StyledWrapper className={className}>{children}</StyledWrapper>
);

const MobileDateInput = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [pushedWhileOpen, setPushedWhileOpen] = useState(false);
  const lang = useSelector((state) => state.lang);
  const buttonRef = useRef();

  //handler functions
  const handleOnChange = (date) => {
    setSelectedDate(date);
    props.handleDateChange(date);
  };

  const handleOpen = () => {
    setOpen(true);
    props.setVisibility(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setVisibility(false);
  };

  const handleClickOutside = (e) => {
    //hacky, but should work:
    if (
      e.path.filter((o) => o.className === "react-datepicker-wrapper").length > 0
    ) {
      setPushedWhileOpen(true);
    }
    handleClose();
  };

  return (
    <StyledDatePicker
      selected={selectedDate}
      value={selectedDate || (lang === "no" ? "År" : "Year")}
	  showYearPicker
      dateFormat={"yyyy"}
      minDate={props.minDate ? props.minDate : null}
      maxDate={props.maxDate ? props.maxDate : null}
      onChange={(date) => handleOnChange(date)}
      onClickOutside={handleClickOutside}
      onSelect={handleClose}
      customInput={
        <Button
          open={open}
          setOpen={handleOpen}
          pushedWhileOpen={pushedWhileOpen}
          setPushedWhileOpen={setPushedWhileOpen}
          ref={buttonRef}
        />
      }
      calendarContainer={CalendarContainer}
      popperPlacement="bottom-start"
      isActive={!props.activeFilter || selectedDate}
    />
  );
};

export default MobileDateInput;

const FilterItem = styled.div`
  padding: 0;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    ${(props) => props.theme.fonts.h7}
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  user-select: none;
  position: relative;
`;

const StyledWrapper = styled.div`
  position: relative;
  background-color: white;
  z-index: 10;
  .react-datepicker__year--container{
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  .react-datepicker-year-header{
		  display: none;
	  }
	  .react-datepicker__year-wrapper{
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.react-datepicker__year-text{
			margin: 5px 0;
		}
	  }
  }
`;
