import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';

import AccordionItem from 'components/partials/AccordionItem';

export default function AccordionModule(props) {
    const { content } = props;

    return (
        <Wrapper>
            <Container>
                <StyledGridElement
                    colStart={2}
                    colEnd={13}
                >
                    {content.map((item, idx) => 
                        <AccordionItem 
                            key={idx} 
                            title={item.title}
                            content={item.content}
                        />
                    )}
                </StyledGridElement>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    user-select: none;
`

const StyledGridElement = styled(GridElement)`
    border-top: 1px solid #000;
`