const defaultState = { open: false };

export default (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case "openSearch/toggle":
            return { ...state, open: !state.open };
        case "openSearch/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};