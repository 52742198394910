import React from 'react';
import styled from 'styled-components';

import Media from 'css/Media';

export default props => {
    const { ratio, children } = props;
    return (
        <StyledDiv ratio={ratio} className={props.className}>{children}</StyledDiv>
    )
}

const StyledDiv = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: ${props => props.ratio === "16:9" ? 56.25 : 75}%;
    overflow: hidden;

    ${Media.greaterThan('s')`
        justify-content: center;
        align-items: center;
    `}
`