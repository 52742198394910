import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import Divider from 'components/Divider';

import Media from 'css/Media';

export const dividerQuery = pageType => `
    ... on ${pageType}_Modules_Builder_DividerModule {
        top
        bottom
        fieldGroupName
      }
`;

export default function DividerModule(props) {
    const { data } = props;
    return (
        <Wrapper top={data.top} bottom={data.bottom}>
            <Container>
                <GridElement
                    colStart={{default: 2, s: 5}}
                    colEnd={{default: 13, s: 24}}
                >
                    <Divider />
                </GridElement>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    padding-top: ${props => 
        props.top === 's' ? 16 : 
        props.top === 'm' ? 32 : 
        props.top === 'l' ? 84 : 0
    }px;
    padding-bottom: ${props => 
        props.bottom === 's' ? 16 : 
        props.bottom === 'm' ? 32 : 
        props.bottom === 'l' ? 84 : 0
    }px;

    ${Media.greaterThan('s')`
        padding-top: ${props => 
            props.top === 's' ? 32 : 
            props.top === 'm' ? 64 : 
            props.top === 'l' ? 100 : 0
        }px;
        padding-bottom: ${props => 
            props.bottom === 's' ? 32 : 
            props.bottom === 'm' ? 64 : 
            props.bottom === 'l' ? 100 : 0
        }px;
    `}
`