import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = React.memo(({
    title,
    keywords,
    description,
    featuredImage,
    imgAlt,
    imgWidth,
    imgHeight,
    published,
    modified,
}) => {
    const fbAppId = process.env.REACT_APP_FACEBOOK_ANALYTICS_CODE;
    
    return (
        <Helmet>
            <title>{title ? title : "nyMusikk"}</title>
            {description ?
                <meta name="description" content={description} />
            : null}
            {keywords ? 
                <meta name="keywords" content={keywords} />
            : null}

            {title ?
                <meta property="og:title" content={title} />
            : null}
            {description ?
                <meta
                    property="og:description"
                    content={description}
                />
            : null}
            {featuredImage ?
                <meta
                    property="og:image"
                    content={featuredImage}
                />
            : null}
            {imgAlt ?
                <meta
                    property="og:image:alt"
                    content={imgAlt}
                />
            : null}
            {imgWidth ?
                <meta
                    property="og:image:width"
                    content={imgWidth}
                />
            : null}
            {imgHeight ?
                <meta
                    property="og:image:height"
                    content={imgHeight}
                />
            : null}

            {fbAppId ?
                <meta
                    property="fb:app_id"
                    content={fbAppId}
                />
            : null}

            {published ?
                <meta
                    property="article:published_time"
                    content={published}
                />
            : null}
            {modified ?
                <meta
                    property="article:modified_time"
                    content={modified}
                />
            : null}

            {title ?
                <meta name="twitter:title" content={title} />
            : null}

            {description ?
                <meta
                    name="twitter:description"
                    content={description}
                />
            : null}

            {featuredImage ?
                <meta
                    name="twitter:image"
                    content={featuredImage}
                />
            : null}
        </Helmet>
    );
});

export default Seo;
