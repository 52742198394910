import React, { useEffect } from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import GridElement from 'components/GridElement';
import Html from 'components/partials/Html';

import Media from 'css/Media';

export const richTextQuery = pageType => `
    ... on ${pageType}_Modules_Builder_RichTextModule {
        content
        fieldGroupName
    }
`

const RichText = props => {
    const moduleRef = React.createRef();
    const { data } = props;
    
    useEffect(() => {
        let module = moduleRef.current;
        makeBoldParagraphs(module);
    }, [moduleRef])

    const makeBoldParagraphs = module => {
        let nodes = module.querySelectorAll('h3');
        if(!nodes) return null;
        let nodeArr = Array.from(nodes);

        nodeArr.map(node => {
            let newNode = document.createElement('p'),
            parent = node.parentNode,
            children = node.childNodes;
            newNode.className = 'bold';
            Array.prototype.forEach.call(children, function(elem) {
                newNode.appendChild(elem);
            });
            parent.replaceChild(newNode, node);
            return null;
        })
    }

    return (
        <Wrapper ref={moduleRef}>
            <Container>
                <GridElement
                    colStart={{default: 2, s: 11}}
                    colEnd={{default: 13, s: 22}}
                >
                    <Html>
                        {data.content}
                    </Html>
                </GridElement>
            </Container>
        </Wrapper>
    )
}

export default RichText;

const Wrapper = styled.section`
    .bold {
        ${props => props.theme.fonts.h3}
        font-weight: ${props => props.hasPreamble ? 'medium' : 'normal'};
    }

    p {
        padding-bottom: 32px;
            
        ${Media.greaterThan('s')`
            padding-bottom: 46px;
        `}
    }
`


