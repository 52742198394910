const defaultState = '';

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "chapter/update":
            return payload;
        case "chapter/clear":
        case "REDUX/CLEAR":
            return defaultState;
        default:
            return state;
    }
};