import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Container from "components/Container";
import GridElement from "components/GridElement";
import A from "components/A";
import Accordion from "components/partials/Accordion";

import Logo from "icons/Logo";

const Footer = (props) => {
  const { siteSettings, footerData, isVisible, lang } = props;
  const isBlurry = useSelector((state) => state.backgroundOpacity);

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  if (!footerData || !siteSettings) return null;
  return (
    <Wrapper isVisible={isVisible} isBlurry={isBlurry}>
      <Container>
        <NewsletterSignupGridElement colStart={2} colEnd={10}>
          {lang === "no" ? (
            <StyledA
              href={siteSettings.newsletterSignupLink.url}
              target={siteSettings.newsletterSignupLink.target}
            >
              {siteSettings.newsletterSignupLink.title}
            </StyledA>
          ) : (
            <StyledA
              href={siteSettings.newsletterSignupLinkEn.url}
              target={siteSettings.newsletterSignupLinkEn.target}
            >
              {siteSettings.newsletterSignupLinkEn.title}
            </StyledA>
          )}
        </NewsletterSignupGridElement>
      </Container>
      <Accordion
        content={
          lang === "no" ? footerData.footerItems : footerData.footerItemsEn
        }
      />
      <Container>
        <LogoGridElement colStart={2} colEnd={13} rowStart={1}>
          <A
            href={lang === "no" ? "/home" : "/en/home-2"}
            onClick={handleLogoClick}
          >
            <Logo />
          </A>
        </LogoGridElement>
        <CredentialsGridElement colStart={2} colEnd={13} rowStart={2}>
          <p>
            <A
              href={
                lang === "no"
                  ? siteSettings.privacyLink.url
                  : siteSettings.privacyLinkEn.url
              }
              target={
                lang === "no"
                  ? siteSettings.privacyLink.target
                  : siteSettings.privacyLinkEn.target
              }
            >
              {lang === "no"
                ? siteSettings.privacyLink.title
                : siteSettings.privacyLinkEn.title}
            </A>
          </p>
          <p>{new Date().getFullYear() + " © nyMusikk"}</p>
          <p>{siteSettings.orgNumber}</p>
        </CredentialsGridElement>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  padding: 96px 0 32px;
  opacity: ${(props) => (props.isVisible ? (props.isBlurry ? 0.05 : 1) : 0)};
  ${(props) => props.theme.variables.transition}
`;

const StyledA = styled.a`
  display: inline-block;
  ${(props) => props.theme.fonts.h7}
  position: relative;
  padding-bottom: 6px !important;

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #000;
    bottom: 0;
    left: 0;
    ${(props) => props.theme.variables.transition}
    transition-duration: 250ms;
  }

  &:active {
    &::after {
      background-color: transparent;
    }
  }

  a {
    &::after {
      position: absolute;
      content: "→";
      right: -14px;
    }
  }
`;

const NewsletterSignupGridElement = styled(GridElement)`
  margin-bottom: 92px;
`;

const LogoGridElement = styled(GridElement)`
  padding: 46px 0;
`;

const CredentialsGridElement = styled(GridElement)`
  p,
  a {
    ${(props) => props.theme.fonts.h7}
    margin-bottom: 11px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }
`;
