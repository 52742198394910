import algoliasearch from 'algoliasearch/lite'

const algoliaClient = algoliasearch('JRPDURNLLI', '8e1d3335ffceec163bb17aa51be2b4ac');

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

export default searchClient;