import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import debounce from "lodash.debounce";

const FadeIn = (props) => {
  const [visible, setVisible] = useState(false);
  const fadeinRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll), 500);
    checkIfInView(1);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    checkIfInView();
  };

  const checkIfInView = (offset) => {
    const triggerOffset = offset ? offset : props.offset || 0;
    if (
      fadeinRef.current &&
      fadeinRef.current.getBoundingClientRect().top + triggerOffset <=
        window.innerHeight &&
      !visible
    ) {
      setVisible(true);
      if (props.onEnter) props.onEnter();
    }
  };

  return (
    <Wrapper
      visible={visible}
      className={props.className}
      shouldScale={props.shouldScale}
      onClick={props.onClick}
    >
      <div ref={fadeinRef} />
      {props.children}
    </Wrapper>
  );
};

export default FadeIn;

const Wrapper = styled.div`
  position: relative;
  opacity: 0;
  ${(props) => props.theme.variables.transition}
  width: 100%;
  transform: ${(props) => (props.shouldScale ? "scale(.98)" : "none")};

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      transform: ${(props) => (props.shouldScale ? "scale(1)" : "none")};
    `}
`;
