import { createGlobalStyle } from "styled-components";

import Fonts from "./Fonts";
import theme from "./theme";
import Media from "./Media";

export default createGlobalStyle`
    
    ${Fonts};

    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; } /* HTML5 display-role reset for older browsers */ article, aside, details, figcaption, figure,  footer, header, hgroup, menu, nav, section { display: block; } body { line-height: 1; } ol, ul { list-style: none; } blockquote, q { quotes: none; } blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; } table { border-collapse: collapse; border-spacing: 0; }

    * {
        box-sizing: border-box;
    }
    
    html {
        max-width: 100%;
        font-size: 16px;
    }

    body {
        font-family: "RightGrotesk", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.3;
        background: ${theme.colors.negative};
        color: ${theme.colors.text};
        min-height: 100vh;
        font-display: fallback;
        max-width:100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    a, a:hover, a:visited, a:active {
        text-decoration: none;
        color: ${theme.colors.text};
        ${theme.variables.transition}
        transition-duration: 250ms;
    }

    ul {
        margin: 0;
        padding:0;
        li {
            margin:0;
            padding:0;
            list-style: none;
        }
    }

    img {
        max-width: 100%;
        height:auto;
    }

    h1 {${theme.fonts.h1}}

    //INPUT STYLE RESET

    input:focus, textarea:focus, select:focus{
        outline: none;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }

    /* Edge */
    :-ms-input-placeholder {
        color: #000000;
    }

    ::-ms-clear { display: none; }

    input::placeholder {
        color: #000000;
    }


    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    button {
        padding: 0;
        border: none;
        outline: none;
        font: inherit;
        color: inherit;
        background: none;
    }

    /* CUSTOM STYLES FOR DATEPICKER */

    .react-datepicker {
        
        &-popper {
            z-index: 10;
            min-width: 100vw;
            top: 0;
            left: -26px!important;

            ${Media.greaterThan("s")`
                min-width: 526px;
                top: 17px!important;
                left: 0!important;
            `}
            ${Media.lessThan("s")`
                position: absolute!important;
                transform: none!important;
                left: 0;
                right: 0;
                top: 64px!important;
            `}

        }
        &__tab-loop {
            
        }

        &__input {
            &-container {
                display: flex;
                width: 100%;
                margin: 0;
                ${Media.greaterThan("s")`
                    margin-right: 32px;
                `}
            }
        }
        &__close-icon {
            cursor: pointer;
            width: 22px;
            height: 22px;
            margin-top: 4px;
            margin-left: 4px;
            background-image: url('/images/close.svg');
            background-repeat: no-repeat;
            background-position: center center;
        }

        &__navigation {
            cursor: pointer;
            ${theme.variables.transition};
            ${theme.fonts.h7}
            
            &:hover {
                opacity: .8;
            }
            &--previous {
                position: absolute;
                left: 32px;
                top: 20px;
                width: 50px;
                height: 50px;
                transform: rotate(180deg);
                background-image: url('/images/chevron.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                color: transparent;
                user-select: none;

                ${Media.greaterThan("s")`
                    top: 25px;
                `}
            }
            &--next {
                position: absolute;
                top: 20px;
                right: 32px;
                width: 50px;
                height: 50px;
                background-image: url('/images/chevron.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                color: transparent;
                user-select: none;
                
                
                ${Media.greaterThan("s")`
                    top: 25px;
                `}
            }
        }

        &__month-container {
            padding: 90px 32px 32px;
        }
        &__header {
            margin-bottom: 16px;
            &__dropdown {
                &--scroll {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 32px;
                    ${theme.fonts.h7}
                }
            }
        }
        &__year {
            width: 100%;
            &-dropdown {
                width: 100%;
                background: white;
                display: flex;
                justify-content: center;

                &-container {
                    cursor: pointer;
                }
            }
            &-option {
                opacity: .6;
                margin: 0 3px;
                cursor: pointer;

                &--selected {
                    display: none;
                }
                &--selected_year {
                    font-weight: 500;
                    opacity: 1;
                }

                ${Media.greaterThan("s")`
                    margin: 0 4px;
                `}
            }
        }
        &__current-month {
            position: absolute;
            right: 50%;
            top: 32px;
            transform: translateX(50%);
            ${theme.fonts.h7}
            
            ${Media.greaterThan("s")`
                ${theme.fonts.h4}
            `}
        }
        &__day-names {
            display: flex;
        }
        &__day-name {
            width: calc(100% / 7);
            margin: 0 auto;
            ${theme.fonts.h7}
            display: flex;
            justify-content: center;
        }
        &__month {

        }
        &__week {
            display: flex;
            margin-bottom: 8px;
        }
        &__day {
            width: calc(100% / 7);
            cursor: pointer;
            ${theme.fonts.h7}
            display: flex;
            justify-content: center;
            
            ${Media.greaterThan("s")`
                ${theme.fonts.h4}    
            `}

            &--disabled {
                opacity: .6;
                cursor: auto;
            }
            
            &--selected {
                outline: 1px dotted black;
                cursor: auto;
            }

            &--today {
                font-weight: 500;
                
            }

            &:focused, &:active, &:visited {
                outline: none;
            }
        }
    }
`;
