import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import InputButton from "./InputButton";
import { useSelector } from "react-redux";

const DateInput = (props) => {
  const [open, setOpen] = useState(false);
  const [pushedWhileOpen, setPushedWhileOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const lang = useSelector((state) => state.lang);
  const buttonRef = useRef();
  //close date input if one of the other selects are open
  useEffect(() => {
    if (props.selectIsOpen) {
      setOpen(false);
    }
  }, [props.selectIsOpen]);

  //custom container for react datepicker
  const CalendarContainer = ({ className, children }) => {
    return (
      <StyledWrapper visible={open} className={className}>
        {children}
      </StyledWrapper>
    );
  };

  //handler functions
  const handleOnChange = (date) => {
    setSelectedDate(date);
    props.handleDateChange(date);
  };

  const handleOpen = () => {
    setOpen(true);
    props.setVisibility(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setVisibility(false);
  };

  const handleClickOutside = (e) => {
    //hacky, but should work:
    if (
      e.path.filter((o) => o.className === "react-datepicker-wrapper").length > 0
    ) {
      setPushedWhileOpen(true);
    }
    handleClose();
  };

  return (
      <StyledDatePicker
        selected={selectedDate}
		value={selectedDate || (lang === "no" ? "År" : "Year")}
		showYearPicker
		dateFormat={'yyyy'}
        minDate={props.minDate ? props.minDate : null}
        maxDate={props.maxDate ? props.maxDate : null}
        onChange={(date) => handleOnChange(date)}
        onClickOutside={handleClickOutside}
        onSelect={handleClose}
        customInput={
          <InputButton
            open={open}
            setOpen={handleOpen}
            pushedWhileOpen={pushedWhileOpen}
            setPushedWhileOpen={setPushedWhileOpen}
            reverse={props.reverse}
            ref={buttonRef}
          />
        }
        isClearable
        calendarContainer={CalendarContainer}
        popperPlacement="bottom-start"
      />
  );
};

export default DateInput;

const StyledDatePicker = styled(DatePicker)`
  user-select: none;

  .react-datepicker-popper {
    display: none;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  background-color: transparent;
  z-index: 10;
  display: ${(props) => (props.visible ? "flex" : "none")};
  .react-datepicker__year--container{
	order: -1;  
  }
  .react-datepicker__navigation{
	&.react-datepicker__navigation--previous{
		transform: rotate(270deg);
		left: 52px;
	}
	&.react-datepicker__navigation--next{
		transform: rotate(90deg);
		top: 100px;
		right: 0;
		left: 54px;
	}
  }
  .react-datepicker__year-text{
	  cursor: pointer;
  }
`;
